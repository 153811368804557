.statsCard {
  composes: cardHover from '~g';
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 22rem;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: var(--spacer-24);
  border-radius: var(--border-radius-10);
  background: var(--card-background);

  &::after {
    position: absolute;
    display: block;

    /* bento border is not using a color within design system */
    border: 1px solid var(--color-alpha-light-5);
    border-radius: var(--border-radius-5);
    content: '';
    inset: 0;
    pointer-events: none;
  }

  &.blurredBackground {
    backdrop-filter: blur(var(--card-background-blur));
    background: var(--color-alpha-dark-50);
  }
}

.backgroundImage {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

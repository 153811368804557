.cardItemSquare {
  composes: cardItem from '../CardItem/CardItem.module.css';

  --shadow-radius: var(--border-radius-5);
  --card-aspect-ratio: 1;
}

.active {
  composes: active from '../CardItem/CardItem.module.css';
}

.title {
  composes: title5 from '~typo';
  display: block;
  color: var(--color-grey-100);
  line-height: 1;
}

.titleWrapper,
.bodyWrapper {
  padding-right: var(--spacer-32);
}

.innerWrapper {
  composes: innerWrapper from '../CardItem/CardItem.module.css';
}

.imageContainer {
  composes: imageContainer from '../CardItem/CardItem.module.css';
}

.shadowContainer {
  composes: shadowContainer from '../CardItem/CardItem.module.css';
}

.glow {
  composes: glow from '../CardItem/CardItem.module.css';
}

.eyebrow {
  composes: eyebrow from '../CardItem/CardItem.module.css';
}

.textContainer {
  composes: textContainer from '../CardItem/CardItem.module.css';
}

.titleWrapper {
  composes: titleWrapper from '../CardItem/CardItem.module.css';
}

.bodyWrapper {
  composes: bodyWrapper from '../CardItem/CardItem.module.css';
}

.bodyContent {
  composes: bodyContent from '../CardItem/CardItem.module.css';
}

.bodies {
  composes: bodies from '../CardItem/CardItem.module.css';
}

.buttonExpand {
  composes: buttonExpand from '../CardItem/CardItem.module.css';
}

.buttonOverlay {
  composes: buttonOverlay from '../CardItem/CardItem.module.css';
}

.imageOverlay {
  composes: imageOverlay from '../CardItem/CardItem.module.css';
}

.imageOverlayHover {
  composes: imageOverlayHover from '../CardItem/CardItem.module.css';
}

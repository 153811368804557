.overflowingMedia {
  position: relative;
  z-index: var(--z-index-2);
  grid-column: start/end;
}

.mediaWrapper {
  width: 200%;
}

.scrubberBar {
  overflow: hidden;
  height: 2px;
  margin-top: var(--spacer-16);
  background: var(--color-alpha-light-10);
  grid-column: start/end;
}

.scrubber {
  display: block;
  width: 60px;
  height: 100%;
  border-radius: 2px;
  background: var(--color-alpha-light-20);
}

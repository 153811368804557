$editorialSmallBreakpoint: 905px;

.cardGridEditorial {
  composes: grid from '~g';
  padding: 0;

  --shadow-radius: var(--border-radius-5);

  .logoWrapper {
    z-index: var(--z-index-2);
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
  }

  .bodies {
    color: var(--color-grey-100);
  }

  .text {
    padding-right: 0;
    padding-left: 0;

    @media (--md) {
      padding-right: 1.6rem;
    }

    @media (--lg) {
      padding-right: 6rem;
    }
  }

  .button {
    padding-left: 0;
  }

  .triggerWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: var(--spacer-124);
    grid-column: start / end;
  }

  .caret {
    margin-left: var(--spacer-16);

    &[aria-expanded='true'] .caretIcon {
      transform: rotate(180deg);
    }

    .caretIcon {
      display: inline-block;
      width: 8.5px;
      height: 8.5px;
      fill: var(--color-grey-100);
      transform: none;
      transition: transform var(--duration-60ms) var(--ease-micro-bounce);
    }
  }
}

.bodies {
  composes: bodyMedium from '~typo';
}

.content {
  composes: subgrid from '~g';
  height: 'auto';
  align-content: start;
  padding: 0;
  row-gap: var(--spacer-84);

  @media (--md) {
    row-gap: var(--spacer-96);
  }

  @media (--lg) {
    row-gap: var(--spacer-124);
  }
}

.card {
  composes: cardHover from '~g';
  grid-column: start / end;

  --item-hover-progress: 0;
  --button-hover-progress: 0;

  @media (hover: hover) {
    &:hover {
      --item-hover-progress: 1;
      --button-hover-progress: 1;
    }
  }

  @media (--md) {
    grid-column: auto / span 6;
  }
}

.invisible {
  display: none;
}

/* contains the card image */
.glowContainer {
  margin-bottom: var(--spacer-16);

  /* increase canvas size to prevent cropping because these are too small */
  --mobile-canvas-scale: 1.1;

  @media (--lg) {
    margin-bottom: var(--spacer-32);
  }

  .glow {
    --glow-translate-x: -50%;
    --glow-translate-y: -40%;
    z-index: var(--z-index-neg);
  }
}

/* contains the card image and glow */
.innerCardContainer {
  position: relative;
  margin-bottom: var(--spacer-32);
  cursor: pointer;
}

.imageContainer {
  position: relative;
  display: block;
  width: 100%;
  mask-image: var(--top-bottom-mask-5px);

  --logo-margin: var(--spacer-24);
  --image-aspect-ratio: 1.33;

  @media (--md) {
    --image-aspect-ratio: 1.4;
    --logo-margin: var(--spacer-32);
  }

  @media (--lg) {
    --image-aspect-ratio: 1.5;
    --logo-margin: var(--spacer-40);
  }

  .logoWrapper {
    --logo-margin: var(--spacer-24);

    @media (--md) {
      --logo-margin: var(--spacer-32);
    }

    @media (--lg) {
      --logo-margin: var(--spacer-40);
    }
    position: absolute;
    bottom: var(--logo-margin);
    left: var(--logo-margin);
  }

  .logo {
    position: relative;

    > svg {
      overflow: visible;
    }
  }
}

.imageElement {
  z-index: var(--z-index-1);
  display: block;
  transform: scale(calc(var(--card-hover-progress, 0) * var(--default-card-hover-scale-amount) + 1));
  transition:
    var(--card-hover-transform-default-transition),
    opacity var(--duration-600ms);
}

.shadowContainer {
  position: absolute;
  z-index: var(--z-index-0);
  inset: 0;
}

.text {
  position: relative;
  z-index: var(--z-index-2);
  margin-bottom: var(--spacer-16);
  @media (--md) {
    margin-bottom: var(--spacer-20);
  }
  @media (--lg) {
    margin-bottom: var(--spacer-24);
  }
}

/* Variables */

$SmallReferenceWidthPercentage: 375 * 100%;
$SmallReferenceHeightPercentage: 590 * 100%;

$MediumReferenceWidthPercentage: 960 * 100%;
$MediumReferenceHeightPercentage: 308 * 100%;

$LargeReferenceWidthPercentage: 1440 * 100%;
$LargeReferenceHeightPercentage: 350 * 100%;

/* Mixins for defining chiclet positioning at each breakpoint */

/* leveraging post-css-calc https://www.npmjs.com/package/postcss-calc
to pre-calculate these values */
@define-mixin chicletSmall $index, $top, $left {
  &:nth-child($index) {
    top: calc($top / $SmallReferenceHeightPercentage);
    left: calc($left / $SmallReferenceWidthPercentage);
  }
}
@define-mixin chicletMedium $index, $top, $left, $parallaxDistanceMultiplier {
  &:nth-child($index) {
    top: calc($top / $MediumReferenceHeightPercentage);
    left: calc($left / $MediumReferenceWidthPercentage);

    --logo-parallax-distance-multiplier: $parallaxDistanceMultiplier;
  }
}
@define-mixin chicletLarge $index, $top, $left {
  &:nth-child($index) {
    top: calc($top / $LargeReferenceHeightPercentage);
    left: calc($left / $LargeReferenceWidthPercentage);
  }
}

/* Styles */

.chicletConstellation {
  composes: grid from '~g';
  position: relative;
  z-index: var(--z-index-neg);

  --module-padding-top: 0;
}

/* used for scroll progress calculation */
.placeholder {
  position: absolute;
  inset: 0 0 -20vh;
  pointer-events: none;
}

.chiclets {
  position: relative;
  z-index: var(--z-index-neg);
  height: 52.5rem;
  margin-right: var(--spacer-12);
  margin-bottom: var(--spacer-40);
  margin-left: var(--spacer-12);
  grid-column: wrapper-start / wrapper-end;

  @media (--md) {
    height: 30.8rem;
  }

  @media (--lg) {
    height: 35rem;
    margin-bottom: var(--spacer-16);
    grid-column: wrapper-start / wrapper-end;
  }

  .chicletItem {
    --logo-translate-x: 0;
    --logo-translate-y: 0;
    --logo-parallax-distance: 10rem;
    position: absolute;
    top: 0;
    left: 0;
    opacity: calc(1 - (var(--constellation-progress) * (0.9 + var(--logo-parallax-distance-multiplier) * 0.1)));

    --logo-parallax-distance-multiplier: 1.5;

    /* Positioning using values from figma converted into percentage */
    @mixin chicletSmall 1, -48, -36;
    @mixin chicletSmall 2, 30, 75;
    @mixin chicletSmall 3, -2, 233;
    @mixin chicletSmall 4, 123, -29;
    @mixin chicletSmall 5, 176, 137;
    @mixin chicletSmall 6, 133, 266;
    @mixin chicletSmall 7, 269, -55;
    @mixin chicletSmall 8, 280, 242;
    @mixin chicletSmall 9, 404, -29;
    @mixin chicletSmall 10, 457, 185;
    @mixin chicletSmall 11, 404, 339;

    /* hide it on mobile as per design request */
    @mixin chicletSmall 12, -999, -999;
    @mixin chicletSmall 13, 332, 89;
    transform: translate3d(var(--logo-translate-x), calc(var(--logo-translate-y) * 1px + var(--constellation-progress) * (var(--logo-parallax-distance) * var(--logo-parallax-distance-multiplier))), 0);

    @media (--md) {
      --logo-parallax-distance-multiplier: 1;

      @mixin chicletMedium 1, -38, 98, 2;
      @mixin chicletMedium 2, -18, 293, 2.2;
      @mixin chicletMedium 3, 98, 391, 2.1;
      @mixin chicletMedium 4, -38, 488, 2;
      @mixin chicletMedium 5, -18, 683, 2.1;
      @mixin chicletMedium 6, -38, 878, 2.2;
      @mixin chicletMedium 7, 214, 64, 1.6;
      @mixin chicletMedium 8, 111, 196, 1.5;
      @mixin chicletMedium 9, 214, 488, 1.7;
      @mixin chicletMedium 10, 110, 586, 1.5;
      @mixin chicletMedium 11, 240, 684, 1.4;
      @mixin chicletMedium 12, 98, 781, 1.5;
      @mixin chicletMedium 13, 240, 294, 1.2;
    }

    @media (--lg) {
      @mixin chicletLarge 1, -18, 123;
      @mixin chicletLarge 2, 2, 324;
      @mixin chicletLarge 3, 71, 496;
      @mixin chicletLarge 4, 18, 711;
      @mixin chicletLarge 5, 18, 1021;
      @mixin chicletLarge 6, 2, 1224;
      @mixin chicletLarge 7, 123, 40;
      @mixin chicletLarge 8, 153, 267;
      @mixin chicletLarge 9, 171, 649;
      @mixin chicletLarge 10, 99, 876;
      @mixin chicletLarge 11, 250, 1050;
      @mixin chicletLarge 12, 123, 1320;
      @mixin chicletLarge 13, 270, 448;
    }
  }

  .contentWrapper {
    grid-column: start/end;
  }

  .featuredLogo {
    justify-content: start;

    @media (--md) {
      justify-content: center;
    }
  }
}

.bookEnd {
  position: relative;
  z-index: var(--z-index-2);
  width: 100%;
  padding-top: var(--top-padding);
  pointer-events: none;

  --top-padding: var(--spacer-160);

  @media (--md) {
    --top-padding: 15rem;
  }

  @media (--lg) {
    --top-padding: 26rem;
  }

  .content {
    --placeholder-color: var(--color-grey-100);
    --input-color: var(--color-grey-100);
    pointer-events: all;
  }
}

.container {
  composes: grid from '~g';

  --bottom-padding: 16rem;
  position: relative;
  padding: 0 0 var(--bottom-padding);

  @media (--md) {
    --bottom-padding: 11.6rem;
  }

  @media (--lg) {
    --bottom-padding: 16rem;
  }
}

.blurs {
  position: absolute;
  width: 100%;
  height: 100%;
}

.blurWrapper {
  position: absolute;
  top: 18%;
  left: 40%;
  height: 100%;
  opacity: 0;
  transition: opacity var(--duration-300ms);
  translate: -50% -50%;

  @media (--md) {
    top: 50%;
    left: 50%;
    height: 240%;
    visibility: hidden;
  }
  @media (min-width: 2400px) {
    mask-image: linear-gradient(to left, transparent, black 10%, black 90%, transparent);
  }
}

.blur {
  position: absolute;
  background-size: cover;
  inset: 0;
  mask-image: linear-gradient(to bottom, transparent, black 30%);
}

.blurDim {
  position: absolute;
  z-index: 0;
  top: 18%;
  left: calc(-1 * var(--grid-margin-size));
  width: calc(100vw + var(--grid-margin-size));
  height: 100%;
  aspect-ratio: var(--aspect-ratio);
  background: linear-gradient(90deg, rgb(0 0 0 / 60%) -0.62%, rgb(0 0 0 / 0%) 49.48%), linear-gradient(270deg, rgb(0 0 0 / 60%) 0%, rgb(0 0 0 / 0%) 50%), linear-gradient(180deg, #06070a 3.49%, rgb(0 0 0 / 0%) 24.77%), linear-gradient(180deg, rgb(0 0 0 / 0%) 36.64%, #000 85.35%),
    linear-gradient(0deg, rgb(0 0 0 / 45%) 0%, rgb(0 0 0 / 45%) 100%);
  translate: 0% -50%;

  @media (--md) {
    top: 50%;
    left: 50%;
    width: 100%;
    height: 240%;
    aspect-ratio: unset;
    translate: -50% -50%;
  }
}

.mobileCarouselContainer {
  @media (--md) {
    display: none;
  }
}

.wrapperWithShadow {
  box-shadow: 20px 28px 28px 0 rgb(0 0 0 / 45%);
}

.slides {
  composes: grid from '~g';
  row-gap: var(--spacer-40);
}

.slideItem {
  composes: subgrid from '~g';
  grid-column: start/end;
}

.slideDescriptionContainer {
  margin-top: var(--spacer-24);
  grid-column: start/end;
}

.carouselMedia {
  composes: grid from '~g';
  position: relative;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;

  --glass-border-color: var(--color-alpha-light-10);
}

.text {
  position: relative;
  z-index: var(--z-index-1);
  margin-bottom: var(--spacer-48);
  grid-column: start / end;

  @media (--md) {
    grid-column: col 3 / span calc(var(--grid-number-of-columns) - 4);
    text-align: center;
  }

  &::before {
    position: absolute;
    z-index: var(--z-index-neg);
    display: block;
    background-image: radial-gradient(farthest-side, rgb(0 0 0 / 40%) 50%, transparent);
    content: '';
    inset: -30% -15%;
  }
}

.dummyGrid {
  grid-column: start/end;

  @media (--md) {
    grid-column: col 2 / col -1;
  }
}

.marginSizer {
  width: calc(var(--grid-gutter-size) + var(--grid-computed-column-width));
  grid-column: start / col 1;
}

.slider {
  position: relative;
  grid-column: start / end;

  @media (--md) {
    grid-column: wrapper-start / wrapper-end;
  }
}

.slides {
  position: relative;
  z-index: var(--z-index-1);
  display: flex;
  width: 100%;
  min-width: 164%;
  flex-wrap: nowrap;
  align-items: center;
  aspect-ratio: 1.75;

  @media (--md) {
    min-width: auto;
    aspect-ratio: unset;
  }
}

.slide {
  position: absolute;
  z-index: var(--slide-z-index);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  box-shadow: 0 15px 35px 0 rgb(0 0 0 / 50%);

  @media (--md) {
    position: unset;

    /* Helps prevent layout shift, will be overriten by Keen slide */
    width: calc(var(--grid-gutter-size) * 9 + var(--grid-computed-column-width) * 10);
    flex-shrink: 0;
    filter: saturate(0.5) brightness(0.5);
  }
}

.overlay {
  position: absolute;
  z-index: var(--z-index-2);
  width: 100%;

  /* cover the glass border */
  height: calc(100% + var(--glass-wrapper-border));
  background: var(--color-alpha-black-100);
  inset: 0;
  opacity: 0;
  @media (--md) {
    display: none;
  }
}

.media {
  position: relative;
  aspect-ratio: 16/9;
}

.carouselArrowsContainer {
  position: relative;
  z-index: var(--z-index-2);
  margin-top: 11%;

  @media (--md) {
    position: absolute;
    margin-top: unset;
  }

  .carouselArrowsButtonPrev,
  .carouselArrowsButtonNext {
    --button-border-color: var(--color-alpha-light-10);
    display: inline-block;

    --button-bg-color: var(--color-alpha-light-10);

    @media (--md) {
      position: absolute;
      z-index: var(--z-index-2);
      top: calc(var(--button-width-half) * -1);
    }
  }

  .carouselArrowsButtonPrev {
    margin-right: var(--spacer-16);
    @media (--md) {
      margin-right: unset;
    }
  }
}

.slide * {
  @media (--md) {
    /* In order to hunt down fps, we also disable the glass effect on desktop before over a blur image the result is less perceivable */
    backdrop-filter: unset;
  }
}

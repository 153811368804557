.textElements {
  composes: grid from '~g';
  position: relative;
  grid-column: start / end;
}

.portableText {
  position: relative;
  z-index: var(--z-index-1);
}

.canvasWrapper {
  position: absolute;
  width: 100%;
  height: calc(100% + 200px);
  inset: -100px 0 0;
  pointer-events: none;
}

.canvasRendered {
  span {
    /* This style is applied once our canvas is rendered, it changes the font
    color to transparent to hide the type without hiding the other UI elements
    such as the highlight color on user select. It's using !important as we
    have to be sure it overrides any other CSS rule */

    /* stylelint-disable-next-line declaration-no-important */
    color: rgb(255 255 255 / 0%) !important;
  }
}

.distortedText {
  position: relative;

  .title2,
  .accents {
    color: var(--main-font-color);
    grid-column: start / end;

    --em-color: var(--color-grey-60);
    text-align: center;
    @media (--md) {
      text-align: left;
    }
  }

  .title2 {
    position: relative;
  }
}

.tube {
  position: absolute;

  --tube-top-offset: calc(100% + 10rem);
  --tube-left-offset: 17vw;
  pointer-events: none;

  @media (--md) {
    --tube-left-offset: 450px;
    --tube-top-offset: 100%;
  }
  @media (--lg) {
    --tube-left-offset: 300px;
  }
}

.accents {
  margin-top: var(--spacer-32);
  color: inherit;
}

.emWithDash {
  composes: labelEm from '~deco';
}

.openQuote {
  @media (--md) {
    position: absolute;
    top: 0;
    right: 100%;
  }
}

.buttonExpand {
  box-shadow: inset 0 0 0 0.125rem var(--color-alpha-hawkes-20);
  transition: transform var(--duration-400ms) var(--ease-embellishment);

  &.open {
    transform: rotate(45deg);

    path {
      fill: var(--button-hover-color);
    }
  }
}

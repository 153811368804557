@value bentosMultipleColumns from '~customBreakpoints';

.bentoCard {
  composes: cardHover from '~g';
  position: relative;
  display: flex;
  overflow: hidden;
  height: 100%;
  flex: 1;
  flex-direction: column;
  align-items: center;
  border-radius: var(--border-radius-10);
  background: var(--card-background);
  text-align: center;

  &::after {
    position: absolute;
    display: block;

    /* bento border is not using a color within design system */
    border: 1px solid var(--color-alpha-light-5);
    border-radius: var(--border-radius-5);
    content: '';
    inset: 0;
    pointer-events: none;
  }
}

.content {
  z-index: var(--z-index-2);
  width: 100%;
  flex: 0 0;
  padding: 0 var(--bento-text-padding);
  margin-top: var(--spacer-16);
  margin-bottom: var(--spacer-16);
  text-align: left;

  --bento-text-padding: var(--spacer-16);

  @media (min-width: bentosMultipleColumns) {
    --bento-text-padding: var(--spacer-24);
  }

  @media (--lg) {
    margin: var(--spacer-24) 0;
  }

  .graphic {
    margin: var(--spacer-48);
  }
}

/* Background wrapper & media classes */
.backgroundWrapper {
  position: absolute;
  z-index: var(--z-index-0);
  width: 100%;
  height: 100%;

  .backgroundMedia {
    position: absolute;
    top: 0;
    width: var(--bento-image-width, 100%);
    height: 100%;
  }
}

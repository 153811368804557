.desktopCarouselContainer {
  position: relative;
  display: none;

  @media (--md) {
    display: grid;
  }
}

.carouselContainer {
  composes: grid from '~g';
  position: relative;
}

.shadow {
  grid-column: start / end;
}

.slidesViewport {
  position: relative;
  z-index: var(--z-index-2);
  overflow: hidden;
  border-radius: 0.2rem;
  aspect-ratio: 1.77777;
}

.slidesInner {
  position: absolute;
  width: 100%;
  height: 100%;
}

.slides {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  list-style: none;
}

.slide {
  flex: 1 0 100%;

  &::after {
    position: absolute;
    z-index: var(--z-index-neg);
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, #000000 -1.26%, rgb(0 0 0 / 0%) 15.94%), linear-gradient(270deg, rgb(0 0 0 / 55.3%) 1.75%, rgb(0 0 0 / 55.3%) 9.43%, rgb(0 0 0 / 48.8%) 100%);
    content: '';
    opacity: var(--slide-overlay-opacity);
  }
}

.slideInner {
  height: 100%;
  transform: translateX(var(--slide-x));
}

.descriptionsContainer {
  composes: grid from '~g';
  position: relative;
  margin-top: var(--spacer-48);
}

.descriptionsSubgrid {
  composes: subgrid from '~g';
  align-items: start;
  grid-auto-flow: column;
  grid-column: start/end;
  grid-template-columns: repeat(4, auto);
}

.descriptionButton {
  opacity: 0.5;
  transition: opacity var(--duration-500ms) ease-out;

  @media (hover: hover) {
    &:hover {
      opacity: 1;
    }
  }

  &.active {
    opacity: 1;
  }
}

.toutCard {
  composes: cardHover from '~g';
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  flex: 1;
  flex-direction: column;
  padding: var(--spacer-32);
  border-radius: var(--border-radius-10);

  /* Tile background */
  background-color: rgb(9 9 16 / 100%);

  &::after {
    position: absolute;
    display: block;

    /* bento border is not using a color within design system */
    border: 1px solid var(--color-alpha-light-5);
    border-radius: var(--border-radius-5);
    content: '';
    inset: 0;
    pointer-events: none;
  }

  .iconWrapper {
    position: relative;
    display: flex;
    width: auto;
    flex-flow: row nowrap;
    align-items: flex-end;
    margin-bottom: var(--spacer-8);

    &.isLogo {
      align-items: center;
      margin-bottom: var(--spacer-16);
    }

    &:not(.isLogo) {
      .icon {
        * {
          fill: var(--color-accent);
        }
      }
    }
  }
}

.cardGridNavItem {
  composes: cardHover from '~g';
  border-radius: var(--border-radius-5);
  background-color: var(--color-alpha-black-100);
  cursor: pointer;
}

.title {
  composes: title55 from '~typo';
}

.textContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.innerWrapper {
  position: relative;
  z-index: var(--z-index-1);
  display: flex;
  overflow: hidden;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  padding: var(--spacer-16);
  border-radius: var(--border-radius-5);
  aspect-ratio: 1;

  @media (--md) {
    padding: var(--spacer-24);
  }
}

.videoContainer {
  position: absolute;
  z-index: var(--z-index-neg);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: var(--spacer-12);

  @media (--md) {
    padding: var(--spacer-24);
  }
}

.videoElement {
  width: 100%;
  height: 100%;
}

.titleWrapper {
  position: relative;
  margin-right: var(--spacer-8);
}

.buttonArrow {
  width: 1.4rem;
  flex-shrink: 0;
  border-radius: 50%;

  @media (--md) {
    width: 2rem;
  }
}

.cardItemLandscape {
  composes: cardItem from '../CardItem/CardItem.module.css';

  --card-aspect-ratio: 1;

  @media (--md) {
    --card-aspect-ratio: 1.43;
  }
}

.active {
  composes: active from '../CardItem/CardItem.module.css';
}

.link {
  color: var(--color-grey-100);
  pointer-events: auto;
  text-decoration: underline;
}

.logoWrapper {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  margin-bottom: var(--spacer-16);
}

.logo {
  position: relative;

  > svg {
    overflow: visible;
  }
}

.bullet {
  position: relative;
  display: inline-block;
  color: var(--color-grey-40);
  font-size: 0.4em;
  line-height: 2;
  vertical-align: middle;
}

.titleWrapper {
  composes: titleWrapper from '../CardItem/CardItem.module.css';
  padding-right: var(--spacer-24);
}

.innerWrapper {
  composes: innerWrapper from '../CardItem/CardItem.module.css';
}

.imageContainer {
  composes: imageContainer from '../CardItem/CardItem.module.css';
}

.image {
  width: 100%;
  height: 100%;

  @media (--md) {
    width: auto;
    height: auto;
  }
}

.shadowContainer {
  composes: shadowContainer from '../CardItem/CardItem.module.css';
}

.eyebrow {
  composes: eyebrow from '../CardItem/CardItem.module.css';
}

.textContainer {
  composes: textContainer from '../CardItem/CardItem.module.css';
}

.title {
  composes: title from '../CardItem/CardItem.module.css';
}

.bodyWrapper {
  composes: bodyWrapper from '../CardItem/CardItem.module.css';
}

.bodyContent {
  composes: bodyContent from '../CardItem/CardItem.module.css';
}

.bodies {
  composes: bodies from '../CardItem/CardItem.module.css';
}

.buttonExpand {
  composes: buttonExpand from '../CardItem/CardItem.module.css';
}

.buttonOverlay {
  composes: buttonOverlay from '../CardItem/CardItem.module.css';
}

.imageOverlay {
  composes: imageOverlay from '../CardItem/CardItem.module.css';
}

.imageOverlayHover {
  composes: imageOverlayHover from '../CardItem/CardItem.module.css';
}

.shadow {
  position: relative;
  z-index: var(--card-index, 1);
  height: 100%;

  --bento-content-horizontal-padding: var(--spacer-24);
}

.errUnknownCardType {
  composes: cardHover from '~g';
  position: relative;
  padding: var(--spacer-24);
  border-radius: var(--border-radius-5);
  background-color: darkviolet;
  font-family: monospace;
  font-size: 1.5rem;
}

.cardGridLayout {
  composes: grid from '~g';
  margin-bottom: var(--spacer-48);
  grid-column: wrapper-start / wrapper-end;

  --shadow-scale: 0.45;

  @media (--lg) {
    margin-bottom: var(--spacer-124);
  }

  &::before {
    content: '';
    grid-column: start / end;
    grid-row: 1;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.title {
  composes: title5 from '~typo';
  z-index: var(--z-index-2);
  margin-bottom: var(--spacer-32);
  color: var(--color-grey-100);
  grid-column: start / end;
  grid-row: 2;

  @media (--md) {
    margin-bottom: var(--spacer-40);
  }

  @media (--lg) {
    margin-bottom: var(--spacer-48);
    grid-column-start: start;
  }
}

.cardItems {
  --grid-card-gap: var(--grid-gutter-size);
  display: flex;
  margin: 0 0 0 var(--grid-computed-margin-width);
  margin-bottom: var(--spacer-16);
  gap: var(--grid-card-gap);
  grid-column: wrapper-start / wrapper-end;

  .isSliderReady & {
    gap: unset;

    @media (--md) {
      gap: var(--grid-card-gap);
    }
  }

  @media (--md) {
    --grid-cards-per-row: 2;
    display: grid;
    margin: unset;
    grid-column: start / end;
    grid-template-columns: repeat(var(--grid-cards-per-row), 1fr);
  }

  @media (--lg) {
    --grid-cards-per-row: 3;
  }
}

.cardItem {
  width: 100%;
  flex-basis: var(--grid-computed-inner-width);
  flex-shrink: 0;

  @media (--md) {
    flex-basis: unset;
    grid-column: span 1;
  }

  &:last-of-type {
    /* need grid margin gap on the right when scrolling all the way */
    flex-basis: calc(var(--grid-computed-inner-width) + var(--grid-margin-size));
    padding-right: var(--grid-margin-size);

    @media (--md) {
      flex-basis: unset;
      padding-right: unset;
    }
  }
}

.carouselNavigation {
  display: flex;
  justify-content: center;
  grid-column: start/end;

  @media (--md) {
    display: none;
  }
}

.FeatureCard_bentoCard___SlXN {
  position: relative;
  display: flex;
  overflow: hidden;
  height: 100%;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  border-radius: var(--border-radius-10);
  background: var(--card-background);
  text-align: center;

  --lockup-justify: center;
}

.FeatureCard_bentoCard___SlXN::after {
    position: absolute;
    display: block;

    /* bento border is not using a color within design system */
    border: 1px solid var(--color-alpha-light-5);
    border-radius: var(--border-radius-5);
    content: '';
    inset: 0;
    pointer-events: none;
  }

.FeatureCard_bentoCard___SlXN.FeatureCard_textPlacementTop__I5zEb {
    flex-direction: column;
    justify-content: flex-start;
  }

.FeatureCard_bentoCard___SlXN.FeatureCard_textPlacementTop__I5zEb .FeatureCard_mediaWrapper__iqX3p {
      align-items: flex-start;
    }

.FeatureCard_bentoCard___SlXN.FeatureCard_textPlacementBottom__0Yh9O {
    flex-direction: column-reverse;

    /*  justify flex end = flex start because flex-direction is reversed */
    justify-content: flex-end;
  }

.FeatureCard_bentoCard___SlXN.FeatureCard_textPlacementBottom__0Yh9O.FeatureCard_multipleRow__YPguV {
      justify-content: space-between;
    }

/* fullbleed background need a specific height since the background image is positioned in absolute  */

.FeatureCard_bentoCard___SlXN.FeatureCard_isCoverBackground__Jr6Vv {
    justify-content: flex-start;
  }

.FeatureCard_bentoCard___SlXN.FeatureCard_isCoverBackground__Jr6Vv.FeatureCard_multipleRow__YPguV {
      min-height: 48.3rem;
    }

@media (min-width: 768px) {

.FeatureCard_bentoCard___SlXN.FeatureCard_isCoverBackground__Jr6Vv:not(.FeatureCard_multipleRow__YPguV).FeatureCard_bentoSizeFull__Kuq28 {
        min-height: 35.6rem
    }
      }

@media (min-width: 1240px) {

.FeatureCard_bentoCard___SlXN.FeatureCard_isCoverBackground__Jr6Vv:not(.FeatureCard_multipleRow__YPguV).FeatureCard_bentoSizeFull__Kuq28 {
        min-height: 50rem
    }
      }

.FeatureCard_content__iE2_r {
  z-index: var(--z-index-2);
  width: 100%;
  flex: 0 0;
  padding: 0 var(--bento-text-padding);
  margin-top: var(--spacer-40);
  margin-bottom: var(--spacer-48);

  --bento-text-padding: var(--spacer-24);
}

@media (min-width: 768px) {

.FeatureCard_content__iE2_r {
    margin-top: var(--spacer-24);
    margin-bottom: var(--spacer-32);

    --bento-text-padding: var(--spacer-16)
}
  }

@media (min-width: 950px) {

.FeatureCard_content__iE2_r {
    margin-top: var(--spacer-32);
    margin-bottom: var(--spacer-40);

    --bento-text-padding: var(--spacer-24)
}
  }

@media (min-width: 1240px) {

.FeatureCard_content__iE2_r {
    margin-top: var(--spacer-40);
    margin-bottom: var(--spacer-48)
}
  }

.FeatureCard_bentoSizeOneHalf__pQz9X .FeatureCard_content__iE2_r {
    max-width: calc(40rem + var(--bento-text-padding)*2);
  }

.FeatureCard_bentoSizeTwoThird__mirf5 .FeatureCard_content__iE2_r,
  .FeatureCard_bentoSizeFull__Kuq28 .FeatureCard_content__iE2_r {
    max-width: calc(42rem + var(--bento-text-padding)*2);
  }

.FeatureCard_textAlignmentLeft__0itvX .FeatureCard_content__iE2_r {
    --bento-text-padding: var(--spacer-24);
    --lockup-justify: start;
  }

@media (min-width: 768px) {

.FeatureCard_textAlignmentLeft__0itvX .FeatureCard_content__iE2_r {
      align-self: flex-start;

      --bento-text-padding: var(--spacer-40);
      text-align: left
  }
    }

.FeatureCard_textAlignmentRight__SmQ5B .FeatureCard_content__iE2_r {
    align-self: flex-end;

    --lockup-justify: end;
    --bento-text-padding: var(--spacer-24);
    text-align: right;
  }

@media (min-width: 768px) {

.FeatureCard_textAlignmentRight__SmQ5B .FeatureCard_content__iE2_r {
      align-self: flex-start;

      --bento-text-padding: var(--spacer-40);
      text-align: left
  }
    }

.FeatureCard_mediaWrapper__iqX3p {
  position: relative;
  display: flex;
  flex: 0 1;
  align-items: flex-start;
  align-self: stretch;
  justify-content: center;
}

.FeatureCard_mediaWrapper__iqX3p.FeatureCard_backgroundOnly__SCrsQ {
    align-items: flex-start;
  }

.FeatureCard_mediaWrapper__iqX3p.FeatureCard_backgroundOnly__SCrsQ .FeatureCard_backgroundWrapper__1SJvr .FeatureCard_backgroundWrapper__1SJvr {
      height: 100%;
    }

/* All background sizes */

.FeatureCard_mediaWrapper__iqX3p.FeatureCard_backgroundOnly__SCrsQ .FeatureCard_backgroundSizeShort__ln1WC {
      min-height: 18.4rem;
    }

@media (min-width: 768px) {

.FeatureCard_mediaWrapper__iqX3p.FeatureCard_backgroundOnly__SCrsQ .FeatureCard_backgroundSizeShort__ln1WC {
        min-height: 13.8rem
    }
      }

@media (min-width: 1240px) {

.FeatureCard_mediaWrapper__iqX3p.FeatureCard_backgroundOnly__SCrsQ .FeatureCard_backgroundSizeShort__ln1WC {
        min-height: 19.2rem
    }
      }

.FeatureCard_mediaWrapper__iqX3p.FeatureCard_backgroundOnly__SCrsQ .FeatureCard_backgroundSizeDefault__ypTTr {
      min-height: 21.8rem;
    }

@media (min-width: 768px) {

.FeatureCard_mediaWrapper__iqX3p.FeatureCard_backgroundOnly__SCrsQ .FeatureCard_backgroundSizeDefault__ypTTr {
        min-height: 16.1rem
    }
      }

@media (min-width: 1240px) {

.FeatureCard_mediaWrapper__iqX3p.FeatureCard_backgroundOnly__SCrsQ .FeatureCard_backgroundSizeDefault__ypTTr {
        min-height: 22.6rem
    }
      }

.FeatureCard_mediaWrapper__iqX3p.FeatureCard_backgroundOnly__SCrsQ .FeatureCard_backgroundSizeTall__7HR5Z {
      min-height: 26.9rem;
    }

@media (min-width: 768px) {

.FeatureCard_mediaWrapper__iqX3p.FeatureCard_backgroundOnly__SCrsQ .FeatureCard_backgroundSizeTall__7HR5Z {
        min-height: 19.6rem
    }
      }

@media (min-width: 1240px) {

.FeatureCard_mediaWrapper__iqX3p.FeatureCard_backgroundOnly__SCrsQ .FeatureCard_backgroundSizeTall__7HR5Z {
        min-height: 27.7rem
    }
      }

/* 1:2 bentos background sizes */

.FeatureCard_bentoSizeOneHalf__pQz9X .FeatureCard_mediaWrapper__iqX3p.FeatureCard_backgroundOnly__SCrsQ .FeatureCard_backgroundSizeShort__ln1WC,
      .FeatureCard_bentoSizeOneHalf__pQz9X .FeatureCard_mediaWrapper__iqX3p.FeatureCard_backgroundOnly__SCrsQ .FeatureCard_backgroundSizeDefault__ypTTr,
      .FeatureCard_bentoSizeOneHalf__pQz9X .FeatureCard_mediaWrapper__iqX3p.FeatureCard_backgroundOnly__SCrsQ .FeatureCard_backgroundSizeTall__7HR5Z {
        min-height: 21.8rem;
      }

@media (min-width: 768px) {

.FeatureCard_bentoSizeOneHalf__pQz9X .FeatureCard_mediaWrapper__iqX3p.FeatureCard_backgroundOnly__SCrsQ .FeatureCard_backgroundSizeShort__ln1WC,
      .FeatureCard_bentoSizeOneHalf__pQz9X .FeatureCard_mediaWrapper__iqX3p.FeatureCard_backgroundOnly__SCrsQ .FeatureCard_backgroundSizeDefault__ypTTr,
      .FeatureCard_bentoSizeOneHalf__pQz9X .FeatureCard_mediaWrapper__iqX3p.FeatureCard_backgroundOnly__SCrsQ .FeatureCard_backgroundSizeTall__7HR5Z {
          min-height: 22.7rem
      }
        }

@media (min-width: 1240px) {

.FeatureCard_bentoSizeOneHalf__pQz9X .FeatureCard_mediaWrapper__iqX3p.FeatureCard_backgroundOnly__SCrsQ .FeatureCard_backgroundSizeShort__ln1WC,
      .FeatureCard_bentoSizeOneHalf__pQz9X .FeatureCard_mediaWrapper__iqX3p.FeatureCard_backgroundOnly__SCrsQ .FeatureCard_backgroundSizeDefault__ypTTr,
      .FeatureCard_bentoSizeOneHalf__pQz9X .FeatureCard_mediaWrapper__iqX3p.FeatureCard_backgroundOnly__SCrsQ .FeatureCard_backgroundSizeTall__7HR5Z {
          min-height: 30.6rem
      }
        }

.FeatureCard_isCoverBackground__Jr6Vv .FeatureCard_mediaWrapper__iqX3p.FeatureCard_backgroundOnly__SCrsQ {
      min-height: 21.8rem;
    }

/* Fullbleed only on medium & Large */

@media (min-width: 768px) {

.FeatureCard_isCoverBackground__Jr6Vv .FeatureCard_mediaWrapper__iqX3p.FeatureCard_backgroundOnly__SCrsQ {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%
    }

        .FeatureCard_isCoverBackground__Jr6Vv .FeatureCard_mediaWrapper__iqX3p.FeatureCard_backgroundOnly__SCrsQ .FeatureCard_backgroundWrapper__1SJvr {
          height: 100%;
        }
      }

.FeatureCard_multipleRow__YPguV.FeatureCard_isCoverBackground__Jr6Vv .FeatureCard_mediaWrapper__iqX3p.FeatureCard_backgroundOnly__SCrsQ {
      position: absolute;
      inset: 0;
    }

.FeatureCard_mediaWrapper__iqX3p.FeatureCard_foregroundOnly__g6tuk.FeatureCard_foregroundLarge__fDkxR,
    .FeatureCard_mediaWrapper__iqX3p.FeatureCard_foregroundOnly__g6tuk.FeatureCard_foregroundBleed__nLRoF {
      justify-content: flex-start;
    }

@media (min-width: 1240px) {

.FeatureCard_mediaWrapper__iqX3p.FeatureCard_foregroundOnly__g6tuk.FeatureCard_foregroundLarge__fDkxR,
    .FeatureCard_mediaWrapper__iqX3p.FeatureCard_foregroundOnly__g6tuk.FeatureCard_foregroundBleed__nLRoF {
        justify-content: stretch
    }
      }

.FeatureCard_mediaWrapper__iqX3p.FeatureCard_backgroundAndForeground__y98nG .FeatureCard_backgroundWrapper__1SJvr {
      position: absolute;
      width: 100%;
      height: 100%;
    }

.FeatureCard_mediaWrapper__iqX3p.FeatureCard_backgroundAndForeground__y98nG .FeatureCard_backgroundMedia__YYqrl {
      padding-bottom: var(--spacer-54);
    }

/* Background wrapper & media classes */

.FeatureCard_backgroundWrapper__1SJvr {
  position: relative;
  z-index: var(--z-index-0);
  width: 100%;
  height: 100%;

  /* force standardized height on mobile */
  flex: 1 1;
}

.FeatureCard_backgroundWrapper__1SJvr .FeatureCard_backgroundMedia__YYqrl {
    position: absolute;
    top: 0;
    left: 50%;
    width: var(--bento-image-width, 100%);
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: bottom;
       object-position: bottom;
    transform: translateX(-50%);
    transition: var(--card-hover-transform-default-transition);
  }

@media (min-width: 768px) {

.FeatureCard_backgroundWrapper__1SJvr .FeatureCard_backgroundMedia__YYqrl {
      -o-object-position: center;
         object-position: center
  }
    }

.FeatureCard_backgroundWrapper__1SJvr .FeatureCard_backgroundMedia__YYqrl.FeatureCard_fullWidth__NvBaw {
      height: 100%;

      --image-aspect-ratio: unset;
    }

.FeatureCard_backgroundWrapper__1SJvr .FeatureCard_backgroundMedia__YYqrl.FeatureCard_fullWidthWithShadows__Pb76Y {
      /* ratio for the fullWidthWithShadows, so that baked shadows go beyond the div */
      height: 155%;
    }

.FeatureCard_backgroundWrapper__1SJvr .FeatureCard_backgroundMedia__YYqrl.FeatureCard_fullWidthWithShadows__Pb76Y.FeatureCard_hotspotIsAtTop__UVVXA {
        -o-object-position: top;
           object-position: top;
      }

@media (min-width: 768px) {

.FeatureCard_backgroundWrapper__1SJvr .FeatureCard_backgroundMedia__YYqrl.FeatureCard_fullWidthWithShadows__Pb76Y.FeatureCard_hotspotIsAtTop__UVVXA {
          -o-object-position: top;
             object-position: top
      }
        }

/* Foreground */

.FeatureCard_foregroundContainer___WJBA {
  z-index: var(--z-index-1);
  width: 100%;
  max-width: 33.6rem;
  height: auto;
  border-radius: var(--border-radius-10);
  margin: var(--bento-foreground-margin);
}

/* text placements related adjustment to foreground  */

.FeatureCard_textPlacementTop__I5zEb .FeatureCard_foregroundContainer___WJBA {
    margin-top: 0;
  }

.FeatureCard_textPlacementBottom__0Yh9O .FeatureCard_foregroundContainer___WJBA {
    margin-bottom: 0;
  }

.FeatureCard_foregroundContainer___WJBA{

  --bento-foreground-margin: var(--spacer-32);
}

@media (min-width: 768px) {

.FeatureCard_foregroundContainer___WJBA {
    --bento-foreground-margin: var(--spacer-16)
}
  }

@media (min-width: 950px) {

.FeatureCard_foregroundContainer___WJBA {
    --bento-foreground-margin: var(--spacer-32)
}
  }

@media (min-width: 1240px) {

.FeatureCard_foregroundContainer___WJBA {
    --bento-foreground-margin: var(--spacer-40)
}
  }

.FeatureCard_foregroundIcon__Toe8s .FeatureCard_foregroundContainer___WJBA {
    margin-top: var(--spacer-32);

    --bento-foreground-margin: 3.75rem;
  }

@media (min-width: 768px) {

.FeatureCard_foregroundIcon__Toe8s .FeatureCard_foregroundContainer___WJBA {
      margin-top: var(--spacer-16);

      --bento-foreground-margin: var(--spacer-16)
  }
    }

@media (min-width: 950px) {

.FeatureCard_foregroundIcon__Toe8s .FeatureCard_foregroundContainer___WJBA {
      margin-top: var(--spacer-32);

      --bento-foreground-margin: var(--spacer-32)
  }
    }

@media (min-width: 1240px) {

.FeatureCard_foregroundIcon__Toe8s .FeatureCard_foregroundContainer___WJBA {
      margin-top: var(--spacer-40);

      --bento-foreground-margin: 3.75rem
  }
    }

.FeatureCard_foregroundContainer___WJBA .FeatureCard_withBorder__uuGPA {
    --border-size: 1.4rem;
  }

.FeatureCard_foregroundContainer___WJBA .FeatureCard_noBorder__z__MA {
    --border-size: 0;
  }

.FeatureCard_foregroundSmall__6O3_0 .FeatureCard_foregroundContainer___WJBA .FeatureCard_noBorder__z__MA {
    /* small foreground that don't have border need a extra padding */
    padding: var(--spacer-6);
  }

.FeatureCard_foregroundContainer___WJBA .FeatureCard_foregroundMedia__tm6bi {
    position: relative;
    overflow: hidden;
  }

/* Bleed only on small & meidum */

.FeatureCard_foregroundLarge__fDkxR .FeatureCard_foregroundContainer___WJBA {
    min-width: 113%;
    max-width: auto;

    --bento-foreground-margin: var(--spacer-32);
  }

@media (min-width: 768px) {

.FeatureCard_foregroundLarge__fDkxR .FeatureCard_foregroundContainer___WJBA {
      max-width: 100%;

      --bento-foreground-margin: var(--spacer-48)
  }
    }

@media (min-width: 1240px) {

.FeatureCard_foregroundLarge__fDkxR .FeatureCard_foregroundContainer___WJBA {
      min-width: auto;

      --bento-foreground-margin: 6rem
  }
    }

.FeatureCard_bentoSizeOneHalf__pQz9X .FeatureCard_foregroundContainer___WJBA {
    margin-top: var(--spacer-32);
  }

@media (min-width: 768px) {

.FeatureCard_bentoSizeOneHalf__pQz9X .FeatureCard_foregroundContainer___WJBA {
      margin-top: var(--spacer-40)
  }
    }

@media (min-width: 1240px) {

.FeatureCard_bentoSizeOneHalf__pQz9X .FeatureCard_foregroundContainer___WJBA {
      margin-top: 7rem
  }
    }

@media (min-width: 768px) {

.FeatureCard_bentoSizeTwoThird__mirf5 .FeatureCard_content__iE2_r {
      /* this is a fix that make sure bottom text aligns with the bento beside
      this assumes this multi-row bento is beside a bento that has a 3-line body copy.
      Which is something design will make sure with copywriter */
      min-height: 8.875rem
  }
    }

/* Two third bento with bleed foreground  */

.FeatureCard_bentoSizeTwoThird__mirf5 .FeatureCard_foregroundBleed__nLRoF .FeatureCard_foregroundContainer___WJBA {
    min-width: 122%;
    max-width: auto;
    justify-content: flex-start;

    /* hide the top border for "bleeding" effect */
    margin-top: -1rem;
    margin-left: var(--bento-foreground-margin);
  }

@media (min-width: 768px) {

.FeatureCard_bentoSizeTwoThird__mirf5 .FeatureCard_foregroundBleed__nLRoF .FeatureCard_foregroundContainer___WJBA {
      margin-top: 0
  }
    }

/* Two third bento that span over multiple rows  */

.FeatureCard_multipleRow__YPguV.FeatureCard_bentoSizeTwoThird__mirf5 .FeatureCard_foregroundBleed__nLRoF {
    flex: 1 1;
    justify-content: flex-start;
  }

@media (min-width: 768px) {

.FeatureCard_multipleRow__YPguV.FeatureCard_bentoSizeTwoThird__mirf5 .FeatureCard_foregroundBleed__nLRoF {
      justify-content: flex-start
  }
    }

.FeatureCard_multipleRow__YPguV.FeatureCard_bentoSizeTwoThird__mirf5 .FeatureCard_foregroundBleed__nLRoF .FeatureCard_foregroundContainer___WJBA {
      margin: var(--bento-foreground-margin);
      margin-bottom: 0;
      aspect-ratio: var(--foreground-aspect-ratio, 16/9);

      --bento-foreground-margin: var(--spacer-32);
    }

@media (min-width: 768px) {

.FeatureCard_multipleRow__YPguV.FeatureCard_bentoSizeTwoThird__mirf5 .FeatureCard_foregroundBleed__nLRoF .FeatureCard_foregroundContainer___WJBA {
        width: auto;
        min-width: 0;
        min-width: initial;
        max-width: none;
        max-width: initial;
        height: calc(100% - var(--bento-foreground-margin) - var(--spacer-12));
        margin-right: 0;

        --bento-foreground-margin: var(--spacer-40)
    }
      }

.FeatureCard_bentoSizeTwoThird__mirf5.FeatureCard_bentoRowPositionEnd__oDhAh .FeatureCard_foregroundBleed__nLRoF .FeatureCard_foregroundContainer___WJBA {
    justify-content: flex-end;
  }

/* Full row bento that bleeds  */

.FeatureCard_bentoSizeFull__Kuq28 .FeatureCard_foregroundBleed__nLRoF .FeatureCard_foregroundContainer___WJBA {
    max-width: 100%;
    margin-top: -1rem;
    margin-right: -1rem;
  }

@media (min-width: 768px) {

.FeatureCard_bentoSizeFull__Kuq28 .FeatureCard_foregroundBleed__nLRoF .FeatureCard_foregroundContainer___WJBA {
      min-width: auto;
      margin-right: var(--bento-foreground-margin)
  }
    }

.FeatureCard_bentoSizeFull__Kuq28 .FeatureCard_foregroundBleed__nLRoF .FeatureCard_foregroundContainer___WJBA .FeatureCard_foregroundMedia__tm6bi {
      width: 100%;
      max-height: 19.4rem;
      -o-object-fit: cover;
         object-fit: cover;
      -o-object-position: bottom;
         object-position: bottom;
    }

@media (min-width: 768px) {

.FeatureCard_bentoSizeFull__Kuq28 .FeatureCard_foregroundBleed__nLRoF .FeatureCard_foregroundContainer___WJBA .FeatureCard_foregroundMedia__tm6bi {
        max-height: 24rem
    }
      }

.FeatureCard_bentoSizeFull__Kuq28 .FeatureCard_foregroundBleed__nLRoF .FeatureCard_foregroundContainer___WJBA .FeatureCard_foregroundMedia__tm6bi img {
        width: auto;
      }

/* Two third bentos on 1 row  */

:not(.FeatureCard_multipleRow__YPguV).FeatureCard_bentoSizeTwoThird__mirf5 .FeatureCard_foregroundBleed__nLRoF .FeatureCard_foregroundContainer___WJBA {
    min-width: 122%;
    max-width: auto;
    justify-content: flex-start;

    /* hide the top border for "bleeding" effect */
    margin-top: -1rem;
    margin-left: var(--bento-foreground-margin);

    --bento-foreground-margin: var(--spacer-32);
  }

@media (min-width: 768px) {

:not(.FeatureCard_multipleRow__YPguV).FeatureCard_bentoSizeTwoThird__mirf5 .FeatureCard_foregroundBleed__nLRoF .FeatureCard_foregroundContainer___WJBA {
      max-width: 100%;

      --bento-foreground-margin: var(--spacer-40)
  }
    }

@media (min-width: 1240px) {

:not(.FeatureCard_multipleRow__YPguV).FeatureCard_bentoSizeTwoThird__mirf5 .FeatureCard_foregroundBleed__nLRoF .FeatureCard_foregroundContainer___WJBA {
      margin-right: var(--bento-foreground-margin)
  }
    }

:not(.FeatureCard_multipleRow__YPguV).FeatureCard_bentoSizeTwoThird__mirf5 .FeatureCard_foregroundBleed__nLRoF .FeatureCard_foregroundContainer___WJBA .FeatureCard_foregroundMedia__tm6bi {
      width: auto;
      height: calc(var(--foreground-media-height, 22.8rem) - var(--border-size, 0));
      -o-object-fit: cover;
         object-fit: cover;
      -o-object-position: bottom;
         object-position: bottom;
    }

@media (min-width: 768px) {

:not(.FeatureCard_multipleRow__YPguV).FeatureCard_bentoSizeTwoThird__mirf5 .FeatureCard_foregroundBleed__nLRoF .FeatureCard_foregroundContainer___WJBA .FeatureCard_foregroundMedia__tm6bi {
        width: 100%;
        max-height: calc(var(--foreground-media-height, 23.6rem) - var(--border-size, 0));

        --foreground-media-height: 23.6rem
    }
      }

:not(.FeatureCard_multipleRow__YPguV).FeatureCard_bentoSizeTwoThird__mirf5 .FeatureCard_foregroundBleed__nLRoF .FeatureCard_foregroundContainer___WJBA .FeatureCard_foregroundMedia__tm6bi img {
        width: auto;
      }

:not(.FeatureCard_multipleRow__YPguV).FeatureCard_bentoSizeTwoThird__mirf5 .FeatureCard_foregroundBleed__nLRoF.FeatureCard_foregroundBleedShort__j6YT5 .FeatureCard_foregroundContainer___WJBA .FeatureCard_foregroundMedia__tm6bi {
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: bottom;
       object-position: bottom;

    --foreground-media-height: 19.4em;
  }

@media (min-width: 768px) {

:not(.FeatureCard_multipleRow__YPguV).FeatureCard_bentoSizeTwoThird__mirf5 .FeatureCard_foregroundBleed__nLRoF.FeatureCard_foregroundBleedShort__j6YT5 .FeatureCard_foregroundContainer___WJBA .FeatureCard_foregroundMedia__tm6bi {
      --foreground-media-height: 20.2rem
  }
    }

:not(.FeatureCard_multipleRow__YPguV).FeatureCard_bentoSizeTwoThird__mirf5 .FeatureCard_foregroundBleed__nLRoF.FeatureCard_foregroundBleedTall__9zoUq .FeatureCard_foregroundContainer___WJBA .FeatureCard_foregroundMedia__tm6bi {
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: bottom;
       object-position: bottom;

    --foreground-media-height: 27.9em;
  }

@media (min-width: 768px) {

:not(.FeatureCard_multipleRow__YPguV).FeatureCard_bentoSizeTwoThird__mirf5 .FeatureCard_foregroundBleed__nLRoF.FeatureCard_foregroundBleedTall__9zoUq .FeatureCard_foregroundContainer___WJBA .FeatureCard_foregroundMedia__tm6bi {
      --foreground-media-height: 28.7rem
  }
    }

.SocialProofCard_bentoCard__uth8C {
  position: relative;
  display: flex;
  overflow: hidden;
  height: 100%;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  border-radius: var(--border-radius-10);
  background: var(--card-background);
  text-align: center;
}

.SocialProofCard_bentoCard__uth8C::after {
    position: absolute;
    display: block;

    /* bento border is not using a color within design system */
    border: 1px solid var(--color-alpha-light-5);
    border-radius: var(--border-radius-5);
    content: '';
    inset: 0;
    pointer-events: none;
  }

.SocialProofCard_content__FnwU4 {
  z-index: var(--z-index-2);
  width: 100%;
  flex: 0 0;
  padding: 0 var(--bento-text-padding);
  margin-top: var(--spacer-16);
  margin-bottom: var(--spacer-16);
  text-align: left;

  --bento-text-padding: var(--spacer-16);
}

@media (min-width: 950px) {

.SocialProofCard_content__FnwU4 {
    --bento-text-padding: var(--spacer-24)
}
  }

@media (min-width: 1240px) {

.SocialProofCard_content__FnwU4 {
    margin: var(--spacer-24) 0
}
  }

.SocialProofCard_content__FnwU4 .SocialProofCard_graphic__yAGhV {
    margin: var(--spacer-48);
  }

/* Background wrapper & media classes */

.SocialProofCard_backgroundWrapper__jMi8T {
  position: absolute;
  z-index: var(--z-index-0);
  width: 100%;
  height: 100%;
}

.SocialProofCard_backgroundWrapper__jMi8T .SocialProofCard_backgroundMedia__HA0yb {
    position: absolute;
    top: 0;
    width: var(--bento-image-width, 100%);
    height: 100%;
  }

.StatsCard_statsCard__U67L3 {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 22rem;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  padding: var(--spacer-24);
  border-radius: var(--border-radius-10);
  background: var(--card-background);
}

  .StatsCard_statsCard__U67L3::after {
    position: absolute;
    display: block;

    /* bento border is not using a color within design system */
    border: 1px solid var(--color-alpha-light-5);
    border-radius: var(--border-radius-5);
    content: '';
    inset: 0;
    pointer-events: none;
  }

  .StatsCard_statsCard__U67L3.StatsCard_blurredBackground__d147z {
    -webkit-backdrop-filter: blur(var(--card-background-blur));
            backdrop-filter: blur(var(--card-background-blur));
    background: var(--color-alpha-dark-50);
  }

.StatsCard_backgroundImage__NdxG9 {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.ToutCard_toutCard__x1I0a {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  flex: 1 1;
  flex-direction: column;
  padding: var(--spacer-32);
  border-radius: var(--border-radius-10);

  /* Tile background */
  background-color: rgb(9 9 16 / 100%);
}

  .ToutCard_toutCard__x1I0a::after {
    position: absolute;
    display: block;

    /* bento border is not using a color within design system */
    border: 1px solid var(--color-alpha-light-5);
    border-radius: var(--border-radius-5);
    content: '';
    inset: 0;
    pointer-events: none;
  }

  .ToutCard_toutCard__x1I0a .ToutCard_iconWrapper__3WNS_ {
    position: relative;
    display: flex;
    width: auto;
    flex-flow: row nowrap;
    align-items: flex-end;
    margin-bottom: var(--spacer-8);
  }

  .ToutCard_toutCard__x1I0a .ToutCard_iconWrapper__3WNS_.ToutCard_isLogo__ij9Xr {
      align-items: center;
      margin-bottom: var(--spacer-16);
    }

  .ToutCard_toutCard__x1I0a .ToutCard_iconWrapper__3WNS_:not(.ToutCard_isLogo__ij9Xr) .ToutCard_icon__Q8Vpc * {
          fill: var(--color-accent);
        }

.CardWrapper_shadow__2KHOe {
  position: relative;
  z-index: var(--card-index, 1);
  height: 100%;

  --bento-content-horizontal-padding: var(--spacer-24);
}

.CardWrapper_errUnknownCardType__KwrNt {
  position: relative;
  padding: var(--spacer-24);
  border-radius: var(--border-radius-5);
  background-color: darkviolet;
  font-family: monospace;
  font-size: 1.5rem;
}

.BookEnd_bookEnd__03_YT {
  position: relative;
  z-index: var(--z-index-2);
  width: 100%;
  padding-top: var(--top-padding);
  pointer-events: none;

  --top-padding: var(--spacer-160);
}

  @media (min-width: 768px) {.BookEnd_bookEnd__03_YT {
    --top-padding: 15rem
}
  }

  @media (min-width: 1240px) {.BookEnd_bookEnd__03_YT {
    --top-padding: 26rem
}
  }

  .BookEnd_bookEnd__03_YT .BookEnd_content__nyy1p {
    --placeholder-color: var(--color-grey-100);
    --input-color: var(--color-grey-100);
    pointer-events: all;
  }

.BookEnd_container__ubcoM {

  --bottom-padding: 16rem;
  position: relative;
  padding: 0 0 var(--bottom-padding);
}

@media (min-width: 768px) {

.BookEnd_container__ubcoM {
    --bottom-padding: 11.6rem
}
  }

@media (min-width: 1240px) {

.BookEnd_container__ubcoM {
    --bottom-padding: 16rem
}
  }

.CardGridEditorial_cardGridEditorial__7PSiC {
  padding: 0;

  --shadow-radius: var(--border-radius-5);
}

  .CardGridEditorial_cardGridEditorial__7PSiC .CardGridEditorial_logoWrapper__2H0Do {
    z-index: var(--z-index-2);
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
  }

  .CardGridEditorial_cardGridEditorial__7PSiC .CardGridEditorial_bodies__v3v7R {
    color: var(--color-grey-100);
  }

  .CardGridEditorial_cardGridEditorial__7PSiC .CardGridEditorial_text__NVoqX {
    padding-right: 0;
    padding-left: 0;
  }

  @media (min-width: 768px) {

  .CardGridEditorial_cardGridEditorial__7PSiC .CardGridEditorial_text__NVoqX {
      padding-right: 1.6rem
  }
    }

  @media (min-width: 1240px) {

  .CardGridEditorial_cardGridEditorial__7PSiC .CardGridEditorial_text__NVoqX {
      padding-right: 6rem
  }
    }

  .CardGridEditorial_cardGridEditorial__7PSiC .CardGridEditorial_button__O1_Qv {
    padding-left: 0;
  }

  .CardGridEditorial_cardGridEditorial__7PSiC .CardGridEditorial_triggerWrapper__ZFrEj {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: var(--spacer-124);
    grid-column: start / end;
  }

  .CardGridEditorial_cardGridEditorial__7PSiC .CardGridEditorial_caret__bZbpF {
    margin-left: var(--spacer-16);
  }

  .CardGridEditorial_cardGridEditorial__7PSiC .CardGridEditorial_caret__bZbpF[aria-expanded='true'] .CardGridEditorial_caretIcon__J666K {
      transform: rotate(180deg);
    }

  .CardGridEditorial_cardGridEditorial__7PSiC .CardGridEditorial_caret__bZbpF .CardGridEditorial_caretIcon__J666K {
      display: inline-block;
      width: 8.5px;
      height: 8.5px;
      fill: var(--color-grey-100);
      transform: none;
      transition: transform var(--duration-60ms) var(--ease-micro-bounce);
    }

.CardGridEditorial_bodies__v3v7R {
}

.CardGridEditorial_content___bM1_ {
  height: 'auto';
  align-content: start;
  padding: 0;
  row-gap: var(--spacer-84);
}

@media (min-width: 768px) {

.CardGridEditorial_content___bM1_ {
    row-gap: var(--spacer-96)
}
  }

@media (min-width: 1240px) {

.CardGridEditorial_content___bM1_ {
    row-gap: var(--spacer-124)
}
  }

.CardGridEditorial_card___kkiR {
  grid-column: start / end;

  --item-hover-progress: 0;
  --button-hover-progress: 0;
}

@media (hover: hover) {
    .CardGridEditorial_card___kkiR:hover {
      --item-hover-progress: 1;
      --button-hover-progress: 1;
    }
  }

@media (min-width: 768px) {

.CardGridEditorial_card___kkiR {
    grid-column: auto / span 6
}
  }

.CardGridEditorial_invisible__BINUo {
  display: none;
}

/* contains the card image */

.CardGridEditorial_glowContainer__1XEdQ {
  margin-bottom: var(--spacer-16);

  /* increase canvas size to prevent cropping because these are too small */
  --mobile-canvas-scale: 1.1;
}

@media (min-width: 1240px) {

.CardGridEditorial_glowContainer__1XEdQ {
    margin-bottom: var(--spacer-32)
}
  }

.CardGridEditorial_glowContainer__1XEdQ .CardGridEditorial_glow__aYe_w {
    --glow-translate-x: -50%;
    --glow-translate-y: -40%;
    z-index: var(--z-index-neg);
  }

/* contains the card image and glow */

.CardGridEditorial_innerCardContainer__n1DMF {
  position: relative;
  margin-bottom: var(--spacer-32);
  cursor: pointer;
}

.CardGridEditorial_imageContainer__o93A_ {
  position: relative;
  display: block;
  width: 100%;
  -webkit-mask-image: var(--top-bottom-mask-5px);
          mask-image: var(--top-bottom-mask-5px);

  --logo-margin: var(--spacer-24);
  --image-aspect-ratio: 1.33;
}

@media (min-width: 768px) {

.CardGridEditorial_imageContainer__o93A_ {
    --image-aspect-ratio: 1.4;
    --logo-margin: var(--spacer-32)
}
  }

@media (min-width: 1240px) {

.CardGridEditorial_imageContainer__o93A_ {
    --image-aspect-ratio: 1.5;
    --logo-margin: var(--spacer-40)
}
  }

.CardGridEditorial_imageContainer__o93A_ .CardGridEditorial_logoWrapper__2H0Do {
    --logo-margin: var(--spacer-24);
  }

@media (min-width: 768px) {

.CardGridEditorial_imageContainer__o93A_ .CardGridEditorial_logoWrapper__2H0Do {
      --logo-margin: var(--spacer-32);
  }
    }

@media (min-width: 1240px) {

.CardGridEditorial_imageContainer__o93A_ .CardGridEditorial_logoWrapper__2H0Do {
      --logo-margin: var(--spacer-40);
  }
    }

.CardGridEditorial_imageContainer__o93A_ .CardGridEditorial_logoWrapper__2H0Do {
    position: absolute;
    bottom: var(--logo-margin);
    left: var(--logo-margin);
}

.CardGridEditorial_imageContainer__o93A_ .CardGridEditorial_logo__Efbil {
    position: relative;
  }

.CardGridEditorial_imageContainer__o93A_ .CardGridEditorial_logo__Efbil > svg {
      overflow: visible;
    }

.CardGridEditorial_imageElement__Bs1Pl {
  z-index: var(--z-index-1);
  display: block;
  transform: scale(calc(var(--card-hover-progress, 0)*var(--default-card-hover-scale-amount) + 1));
  transition:
    var(--card-hover-transform-default-transition),
    opacity var(--duration-600ms);
}

.CardGridEditorial_shadowContainer__0Mf_i {
  position: absolute;
  z-index: var(--z-index-0);
  inset: 0;
}

.CardGridEditorial_text__NVoqX {
  position: relative;
  z-index: var(--z-index-2);
  margin-bottom: var(--spacer-16);
}

@media (min-width: 768px) {

.CardGridEditorial_text__NVoqX {
    margin-bottom: var(--spacer-20)
}
  }

@media (min-width: 1240px) {

.CardGridEditorial_text__NVoqX {
    margin-bottom: var(--spacer-24)
}
  }

.ButtonExpand_buttonExpand__PTIxu {
  box-shadow: inset 0 0 0 0.125rem var(--color-alpha-hawkes-20);
  transition: transform var(--duration-400ms) var(--ease-embellishment);
}

  .ButtonExpand_buttonExpand__PTIxu.ButtonExpand_open___XC1c {
    transform: rotate(45deg);
  }

  .ButtonExpand_buttonExpand__PTIxu.ButtonExpand_open___XC1c path {
      fill: var(--button-hover-color);
    }

.CardItem_cardItem__Xp92g {
  position: relative;
  text-align: left;

  --card-aspect-ratio: 1.2;
  --shadow-radius: var(--border-radius-5);
}

  @media (min-width: 768px) {.CardItem_cardItem__Xp92g {
    --card-aspect-ratio: 1.3
}
  }

  @media (hover: hover) {
    .CardItem_cardItem__Xp92g:hover {
      --item-hover-progress: 1;
    }

      .CardItem_cardItem__Xp92g:hover .CardItem_buttonExpand__NhXxb {
        --button-color: var(--color-grey-00);
      }
  }

.CardItem_active__gZt3Y {
  --card-hover-progress: 1;
  --item-hover-progress: 1;
}

.CardItem_active__gZt3Y .CardItem_textContainer__rpZfX {
    transition: transform var(--duration-400ms) var(--ease-basic-butter) 0.02s;
  }

.CardItem_active__gZt3Y .CardItem_bodyWrapper__bORrs {
    opacity: 1;
    transition:
      opacity var(--duration-400ms) var(--ease-embellishment) var(--duration-60ms),
      visibility 0ms;
    visibility: visible;
  }

.CardItem_active__gZt3Y .CardItem_imageOverlay__nJEDL {
    opacity: 0;
  }

.CardItem_active__gZt3Y .CardItem_imageOverlayHover__U01_l {
    opacity: 1;
  }

.CardItem_innerWrapper__q66wR {
  position: relative;
  z-index: var(--z-index-1);
  display: flex;
  overflow: hidden;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
  padding: var(--spacer-24);
  border-radius: var(--shadow-radius);
  aspect-ratio: var(--card-aspect-ratio, 1);
}

.CardItem_innerWrapper__q66wR::after {
    position: absolute;
    border-radius: var(--shadow-radius);
    box-shadow: inset 0 0 0 0.1rem var(--color-alpha-light-5);
    content: '';
    inset: 0;
  }

.CardItem_imageOverlay__nJEDL {
  transition: opacity var(--duration-400ms);
}

.CardItem_imageOverlayHover__U01_l {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translateY(0);
  transition: opacity var(--duration-400ms);
}

.CardItem_imageContainer__hgLV0 {
  position: absolute;
  z-index: var(--z-index-neg);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(calc(var(--card-hover-progress, 0)*var(--default-card-hover-scale-amount) + 1));
  transition: var(--card-hover-transform-default-transition);
}

.CardItem_shadowContainer__jhj0K {
  z-index: var(--z-index-1);
}

.CardItem_glow__VNwZM {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.CardItem_eyebrow__XqNGa,
.CardItem_textContainer__rpZfX {
  pointer-events: none;
}

.CardItem_eyebrow__XqNGa {
  position: absolute;
  z-index: var(--z-index-0);
  top: 0;
  left: 0;
  padding: var(--spacer-24);
  color: var(--color-accent);
  font-weight: 600;
  white-space: nowrap;
}

.CardItem_textContainer__rpZfX {
  z-index: var(--z-index-1);
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  transform: translate3d(0, var(--translate-offset), 0);
  transition: transform var(--duration-400ms) var(--ease-embellishment) 0s;
}

.CardItem_titleWrapper__uB3_C {
  position: relative;
}

.CardItem_title__Zzuhh {
  display: block;
  color: var(--color-grey-80);
  line-height: 1.3;
}

.CardItem_bodyWrapper__bORrs {
  position: absolute;
  top: 100%;
  display: flex;
  flex-flow: row wrap;
  opacity: 0;
  transition:
    opacity var(--duration-200ms) var(--ease-embellishment) 0s,
    visibility 0ms linear var(--duration-200ms);
  visibility: hidden;
}

.CardItem_bodyContent__dqJ5a {
  flex: 1 0 100%;
  margin-top: var(--spacer-12);
}

.CardItem_bodies__Exlkb {
  margin-top: var(--spacer-8);
  color: var(--color-grey-80);
}

.CardItem_bodies__Exlkb:first-child {
    margin-top: 0;
  }

.CardItem_link__4nGHg {
  color: var(--color-grey-60);
  pointer-events: auto;
  text-decoration: underline;
}

.CardItem_buttonExpand__NhXxb {
  position: absolute;
  z-index: var(--z-index-1);
  flex-grow: 0;
  flex-shrink: 0;
  inset: auto var(--spacer-24) var(--spacer-24) auto;
  pointer-events: none;

  --button-bg-color: var(--color-alpha-grey-80);
  --button-border-color: var(--color-alpha-light-20);
}

.CardItem_buttonOverlay__rQgK4 {
  position: absolute;
  z-index: var(--z-index-1);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.CardItemLandscape_cardItemLandscape__nK6K7 {

  --card-aspect-ratio: 1;
}

  @media (min-width: 768px) {.CardItemLandscape_cardItemLandscape__nK6K7 {
    --card-aspect-ratio: 1.43
}
  }

.CardItemLandscape_active__QrlDM {
}

.CardItemLandscape_link__eiMTl {
  color: var(--color-grey-100);
  pointer-events: auto;
  text-decoration: underline;
}

.CardItemLandscape_logoWrapper__tJYES {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  margin-bottom: var(--spacer-16);
}

.CardItemLandscape_logo__vrUYo {
  position: relative;
}

.CardItemLandscape_logo__vrUYo > svg {
    overflow: visible;
  }

.CardItemLandscape_bullet__16aOz {
  position: relative;
  display: inline-block;
  color: var(--color-grey-40);
  font-size: 0.4em;
  line-height: 2;
  vertical-align: middle;
}

.CardItemLandscape_titleWrapper__PHrEp {
  padding-right: var(--spacer-24);
}

.CardItemLandscape_innerWrapper__umSRD {
}

.CardItemLandscape_imageContainer__Jk1sU {
}

.CardItemLandscape_image__svRrN {
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {

.CardItemLandscape_image__svRrN {
    width: auto;
    height: auto
}
  }

.CardItemLandscape_shadowContainer__GzY5J {
}

.CardItemLandscape_eyebrow__EbzYP {
}

.CardItemLandscape_textContainer__F0Une {
}

.CardItemLandscape_title__cdIZw {
}

.CardItemLandscape_bodyWrapper__ka71c {
}

.CardItemLandscape_bodyContent__rZkHM {
}

.CardItemLandscape_bodies__4UDs2 {
}

.CardItemLandscape_buttonExpand__zoENS {
}

.CardItemLandscape_buttonOverlay__kGVxa {
}

.CardItemLandscape_imageOverlay__Q4_aO {
}

.CardItemLandscape_imageOverlayHover__ZOaz1 {
}

.CardItemSquare_cardItemSquare__uH_V4 {

  --shadow-radius: var(--border-radius-5);
  --card-aspect-ratio: 1;
}

.CardItemSquare_active__XLpLY {
}

.CardItemSquare_title__TVZxG {
  display: block;
  color: var(--color-grey-100);
  line-height: 1;
}

.CardItemSquare_titleWrapper___qZ4b,
.CardItemSquare_bodyWrapper__vCQs6 {
  padding-right: var(--spacer-32);
}

.CardItemSquare_innerWrapper__HctBm {
}

.CardItemSquare_imageContainer__2RwEu {
}

.CardItemSquare_shadowContainer__49M_7 {
}

.CardItemSquare_glow__3YBxN {
}

.CardItemSquare_eyebrow__yQwEG {
}

.CardItemSquare_textContainer__cpxHy {
}

.CardItemSquare_titleWrapper___qZ4b {
}

.CardItemSquare_bodyWrapper__vCQs6 {
}

.CardItemSquare_bodyContent__Rv4D_ {
}

.CardItemSquare_bodies__x3pfF {
}

.CardItemSquare_buttonExpand__pfLc5 {
}

.CardItemSquare_buttonOverlay__JOtbA {
}

.CardItemSquare_imageOverlay__mNNcW {
}

.CardItemSquare_imageOverlayHover__JYKIo {
}

.CardGridLandscapeSquare_cardGridLayout__YTPCI {
  margin-bottom: var(--spacer-48);
  grid-column: wrapper-start / wrapper-end;

  --shadow-scale: 0.45;
}

  @media (min-width: 1240px) {.CardGridLandscapeSquare_cardGridLayout__YTPCI {
    margin-bottom: var(--spacer-124)
}
  }

  .CardGridLandscapeSquare_cardGridLayout__YTPCI::before {
    content: '';
    grid-column: start / end;
    grid-row: 1;
  }

  .CardGridLandscapeSquare_cardGridLayout__YTPCI:last-child {
    margin-bottom: 0;
  }

.CardGridLandscapeSquare_title__e2gpC {
  z-index: var(--z-index-2);
  margin-bottom: var(--spacer-32);
  color: var(--color-grey-100);
  grid-column: start / end;
  grid-row: 2;
}

@media (min-width: 768px) {

.CardGridLandscapeSquare_title__e2gpC {
    margin-bottom: var(--spacer-40)
}
  }

@media (min-width: 1240px) {

.CardGridLandscapeSquare_title__e2gpC {
    margin-bottom: var(--spacer-48);
    grid-column-start: start
}
  }

.CardGridLandscapeSquare_cardItems__UdTjt {
  --grid-card-gap: var(--grid-gutter-size);
  display: flex;
  margin: 0 0 0 var(--grid-computed-margin-width);
  margin-bottom: var(--spacer-16);
  gap: var(--grid-card-gap);
  grid-column: wrapper-start / wrapper-end;
}

.CardGridLandscapeSquare_isSliderReady__HoPF_ .CardGridLandscapeSquare_cardItems__UdTjt {
    gap: initial;
  }

@media (min-width: 768px) {

.CardGridLandscapeSquare_isSliderReady__HoPF_ .CardGridLandscapeSquare_cardItems__UdTjt {
      gap: var(--grid-card-gap)
  }
    }

@media (min-width: 768px) {

.CardGridLandscapeSquare_cardItems__UdTjt {
    --grid-cards-per-row: 2;
    display: grid;
    margin: 0;
    margin: initial;
    grid-column: start / end;
    grid-template-columns: repeat(var(--grid-cards-per-row), 1fr)
}
  }

@media (min-width: 1240px) {

.CardGridLandscapeSquare_cardItems__UdTjt {
    --grid-cards-per-row: 3
}
  }

.CardGridLandscapeSquare_cardItem___dblX {
  width: 100%;
  flex-basis: var(--grid-computed-inner-width);
  flex-shrink: 0;
}

@media (min-width: 768px) {

.CardGridLandscapeSquare_cardItem___dblX {
    flex-basis: initial;
    grid-column: span 1
}
  }

.CardGridLandscapeSquare_cardItem___dblX:last-of-type {
    /* need grid margin gap on the right when scrolling all the way */
    flex-basis: calc(var(--grid-computed-inner-width) + var(--grid-margin-size));
    padding-right: var(--grid-margin-size);
  }

@media (min-width: 768px) {

.CardGridLandscapeSquare_cardItem___dblX:last-of-type {
      flex-basis: initial;
      padding-right: 0;
      padding-right: initial
  }
    }

.CardGridLandscapeSquare_carouselNavigation__B4VqX {
  display: flex;
  justify-content: center;
  grid-column: start/end;
}

@media (min-width: 768px) {

.CardGridLandscapeSquare_carouselNavigation__B4VqX {
    display: none
}
  }

.CardGridNav_cardGridLayout__U6IEp {
  margin-bottom: var(--spacer-48);
  grid-column: wrapper-start / wrapper-end;
}

.CardGridNav_cardItems__QdhyZ {
  position: relative;
  gap: var(--grid-gutter-size);
}

@media (hover: hover) {
      .CardGridNav_cardItems__QdhyZ:has(.CardGridNav_cardItem__KKsFt:hover) .CardGridNav_cardItem__KKsFt .CardGridNav_cardContent__QGr0w {
        opacity: 0.5;
      }

    .CardGridNav_cardItems__QdhyZ .CardGridNav_cardItem__KKsFt:hover {
      --border-color: var(--color-alpha-light-20);
    }

      .CardGridNav_cardItems__QdhyZ .CardGridNav_cardItem__KKsFt:hover .CardGridNav_cardContent__QGr0w {
        /* stylelint-disable-next-line declaration-no-important */
        opacity: 1 !important;
      }
  }

.CardGridNav_cardItem__KKsFt {
  border: 1px solid var(--border-color);
  grid-column: auto / span 2;
  transition: border var(--duration-500ms) var(--ease-out);

  --border-color: var(--color-alpha-light-10);
}

@media (min-width: 768px) {

.CardGridNav_cardItem__KKsFt {
    flex-basis: initial;
    grid-column: auto / span 4
}
  }

.CardGridNav_cardItem__KKsFt .CardGridNav_cardContent__QGr0w {
    transition: opacity var(--duration-500ms) var(--ease-out);
  }

.CardGridNavItem_cardGridNavItem__Vacwg {
  border-radius: var(--border-radius-5);
  background-color: var(--color-alpha-black-100);
  cursor: pointer;
}

.CardGridNavItem_title__mpcXJ {
}

.CardGridNavItem_textContainer__5l6fH {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.CardGridNavItem_innerWrapper__Jq_sb {
  position: relative;
  z-index: var(--z-index-1);
  display: flex;
  overflow: hidden;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  padding: var(--spacer-16);
  border-radius: var(--border-radius-5);
  aspect-ratio: 1;
}

@media (min-width: 768px) {

.CardGridNavItem_innerWrapper__Jq_sb {
    padding: var(--spacer-24)
}
  }

.CardGridNavItem_videoContainer__BQOTg {
  position: absolute;
  z-index: var(--z-index-neg);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: var(--spacer-12);
}

@media (min-width: 768px) {

.CardGridNavItem_videoContainer__BQOTg {
    padding: var(--spacer-24)
}
  }

.CardGridNavItem_videoElement__pVmK1 {
  width: 100%;
  height: 100%;
}

.CardGridNavItem_titleWrapper__GbBL6 {
  position: relative;
  margin-right: var(--spacer-8);
}

.CardGridNavItem_buttonArrow__fndKK {
  width: 1.4rem;
  flex-shrink: 0;
  border-radius: 50%;
}

@media (min-width: 768px) {

.CardGridNavItem_buttonArrow__fndKK {
    width: 2rem
}
  }

.Carousel_carousel__eOdeG {
  position: relative;
  overflow: hidden;
}

.blurredImages_blurs__YwoJb {
  position: absolute;
  width: 100%;
  height: 100%;
}

.blurredImages_blurWrapper__Yq99T {
  position: absolute;
  top: 18%;
  left: 40%;
  height: 100%;
  opacity: 0;
  transition: opacity var(--duration-300ms);
  translate: -50% -50%;
}

@media (min-width: 768px) {

.blurredImages_blurWrapper__Yq99T {
    top: 50%;
    left: 50%;
    height: 240%;
    visibility: hidden
}
  }

@media (min-width: 2400px) {

.blurredImages_blurWrapper__Yq99T {
    -webkit-mask-image: linear-gradient(to left, transparent, black 10%, black 90%, transparent);
            mask-image: linear-gradient(to left, transparent, black 10%, black 90%, transparent)
}
  }

.blurredImages_blur__y3aFR {
  position: absolute;
  background-size: cover;
  inset: 0;
  -webkit-mask-image: linear-gradient(to bottom, transparent, black 30%);
          mask-image: linear-gradient(to bottom, transparent, black 30%);
}

.blurredImages_blurDim__UIoD6 {
  position: absolute;
  z-index: 0;
  top: 18%;
  left: calc(var(--grid-margin-size)*-1);
  width: calc(100vw + var(--grid-margin-size));
  height: 100%;
  aspect-ratio: var(--aspect-ratio);
  background: linear-gradient(90deg, rgb(0 0 0 / 60%) -0.62%, rgb(0 0 0 / 0%) 49.48%), linear-gradient(270deg, rgb(0 0 0 / 60%) 0%, rgb(0 0 0 / 0%) 50%), linear-gradient(180deg, #06070a 3.49%, rgb(0 0 0 / 0%) 24.77%), linear-gradient(180deg, rgb(0 0 0 / 0%) 36.64%, #000 85.35%),
    linear-gradient(0deg, rgb(0 0 0 / 45%) 0%, rgb(0 0 0 / 45%) 100%);
  translate: 0% -50%;
}

@media (min-width: 768px) {

.blurredImages_blurDim__UIoD6 {
    top: 50%;
    left: 50%;
    width: 100%;
    height: 240%;
    aspect-ratio: unset;
    translate: -50% -50%
}
  }

.CarouselMedia_carouselMedia__nXAAm {
  position: relative;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;

  --glass-border-color: var(--color-alpha-light-10);
}

.CarouselMedia_text__6nc1D {
  position: relative;
  z-index: var(--z-index-1);
  margin-bottom: var(--spacer-48);
  grid-column: start / end;
}

@media (min-width: 768px) {

.CarouselMedia_text__6nc1D {
    grid-column: col 3 / span calc(var(--grid-number-of-columns) - 4);
    text-align: center
}
  }

.CarouselMedia_text__6nc1D::before {
    position: absolute;
    z-index: var(--z-index-neg);
    display: block;
    background-image: radial-gradient(farthest-side, rgb(0 0 0 / 40%) 50%, transparent);
    content: '';
    inset: -30% -15%;
  }

.CarouselMedia_dummyGrid__wzquJ {
  grid-column: start/end;
}

@media (min-width: 768px) {

.CarouselMedia_dummyGrid__wzquJ {
    grid-column: col 2 / col -1
}
  }

.CarouselMedia_marginSizer__Jueo4 {
  width: calc(var(--grid-gutter-size) + var(--grid-computed-column-width));
  grid-column: start / col 1;
}

.CarouselMedia_slider__0jKF4 {
  position: relative;
  grid-column: start / end;
}

@media (min-width: 768px) {

.CarouselMedia_slider__0jKF4 {
    grid-column: wrapper-start / wrapper-end
}
  }

.CarouselMedia_slides__acIsr {
  position: relative;
  z-index: var(--z-index-1);
  display: flex;
  width: 100%;
  min-width: 164%;
  flex-wrap: nowrap;
  align-items: center;
  aspect-ratio: 1.75;
}

@media (min-width: 768px) {

.CarouselMedia_slides__acIsr {
    min-width: auto;
    aspect-ratio: unset
}
  }

.CarouselMedia_slide__bwc22 {
  position: absolute;
  z-index: var(--slide-z-index);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  box-shadow: 0 15px 35px 0 rgb(0 0 0 / 50%);
}

@media (min-width: 768px) {

.CarouselMedia_slide__bwc22 {
    position: static;
    position: initial;

    /* Helps prevent layout shift, will be overriten by Keen slide */
    width: calc(var(--grid-gutter-size)*9 + var(--grid-computed-column-width)*10);
    flex-shrink: 0;
    filter: saturate(0.5) brightness(0.5)
}
  }

.CarouselMedia_overlay__46Lhz {
  position: absolute;
  z-index: var(--z-index-2);
  width: 100%;

  /* cover the glass border */
  height: calc(100% + var(--glass-wrapper-border));
  background: var(--color-alpha-black-100);
  inset: 0;
  opacity: 0;
}

@media (min-width: 768px) {

.CarouselMedia_overlay__46Lhz {
    display: none
}
  }

.CarouselMedia_media__UJuff {
  position: relative;
  aspect-ratio: 16/9;
}

.CarouselMedia_carouselArrowsContainer__52h2j {
  position: relative;
  z-index: var(--z-index-2);
  margin-top: 11%;
}

@media (min-width: 768px) {

.CarouselMedia_carouselArrowsContainer__52h2j {
    position: absolute;
    margin-top: 0;
    margin-top: initial
}
  }

.CarouselMedia_carouselArrowsContainer__52h2j .CarouselMedia_carouselArrowsButtonPrev__FxsgU,
  .CarouselMedia_carouselArrowsContainer__52h2j .CarouselMedia_carouselArrowsButtonNext__aLhCp {
    --button-border-color: var(--color-alpha-light-10);
    display: inline-block;

    --button-bg-color: var(--color-alpha-light-10);
  }

@media (min-width: 768px) {

.CarouselMedia_carouselArrowsContainer__52h2j .CarouselMedia_carouselArrowsButtonPrev__FxsgU,
  .CarouselMedia_carouselArrowsContainer__52h2j .CarouselMedia_carouselArrowsButtonNext__aLhCp {
      position: absolute;
      z-index: var(--z-index-2);
      top: calc(var(--button-width-half)*-1)
  }
    }

.CarouselMedia_carouselArrowsContainer__52h2j .CarouselMedia_carouselArrowsButtonPrev__FxsgU {
    margin-right: var(--spacer-16);
  }

@media (min-width: 768px) {

.CarouselMedia_carouselArrowsContainer__52h2j .CarouselMedia_carouselArrowsButtonPrev__FxsgU {
      margin-right: 0;
      margin-right: initial
  }
    }

@media (min-width: 768px) {

.CarouselMedia_slide__bwc22 * {
    /* In order to hunt down fps, we also disable the glass effect on desktop before over a blur image the result is less perceivable */
    -webkit-backdrop-filter: initial;
            backdrop-filter: initial
}
  }

.CarouselWithToutsDesktop_desktopCarouselContainer__3QROG {
  position: relative;
  display: none;
}

  @media (min-width: 768px) {.CarouselWithToutsDesktop_desktopCarouselContainer__3QROG {
    display: grid
}
  }

.CarouselWithToutsDesktop_carouselContainer__Oz2BI {
  position: relative;
}

.CarouselWithToutsDesktop_shadow__HvxD6 {
  grid-column: start / end;
}

.CarouselWithToutsDesktop_slidesViewport__InjRM {
  position: relative;
  z-index: var(--z-index-2);
  overflow: hidden;
  border-radius: 0.2rem;
  aspect-ratio: 1.77777;
}

.CarouselWithToutsDesktop_slidesInner__tgygG {
  position: absolute;
  width: 100%;
  height: 100%;
}

.CarouselWithToutsDesktop_slides__IjMvU {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  list-style: none;
}

.CarouselWithToutsDesktop_slide__QwtRF {
  flex: 1 0 100%;
}

.CarouselWithToutsDesktop_slide__QwtRF::after {
    position: absolute;
    z-index: var(--z-index-neg);
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, #000000 -1.26%, rgb(0 0 0 / 0%) 15.94%), linear-gradient(270deg, rgb(0 0 0 / 55.3%) 1.75%, rgb(0 0 0 / 55.3%) 9.43%, rgb(0 0 0 / 48.8%) 100%);
    content: '';
    opacity: var(--slide-overlay-opacity);
  }

.CarouselWithToutsDesktop_slideInner__ChTLs {
  height: 100%;
  transform: translateX(var(--slide-x));
}

.CarouselWithToutsDesktop_descriptionsContainer__g6EKQ {
  position: relative;
  margin-top: var(--spacer-48);
}

.CarouselWithToutsDesktop_descriptionsSubgrid__piUbV {
  align-items: start;
  grid-auto-flow: column;
  grid-column: start/end;
  grid-template-columns: repeat(4, auto);
}

.CarouselWithToutsDesktop_descriptionButton__dkh_I {
  opacity: 0.5;
  transition: opacity var(--duration-500ms) ease-out;
}

@media (hover: hover) {
    .CarouselWithToutsDesktop_descriptionButton__dkh_I:hover {
      opacity: 1;
    }
  }

.CarouselWithToutsDesktop_descriptionButton__dkh_I.CarouselWithToutsDesktop_active__Y2jef {
    opacity: 1;
  }

.CarouselWithToutsSlide_carouselSlide__elTLR {
  position: relative;
}

.CarouselWithToutsSlide_media__oTXh0 {
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.OverflowingMedia_overflowingMedia__Ij8vV {
  position: relative;
  z-index: var(--z-index-2);
  grid-column: start/end;
}

.OverflowingMedia_mediaWrapper__l3Iqa {
  width: 200%;
}

.OverflowingMedia_scrubberBar__KzhK5 {
  overflow: hidden;
  height: 2px;
  margin-top: var(--spacer-16);
  background: var(--color-alpha-light-10);
  grid-column: start/end;
}

.OverflowingMedia_scrubber__v29kV {
  display: block;
  width: 60px;
  height: 100%;
  border-radius: 2px;
  background: var(--color-alpha-light-20);
}

@media (min-width: 768px) {.CarouselWithToutsMobile_mobileCarouselContainer__e17ZS {
    display: none
}
  }

.CarouselWithToutsMobile_wrapperWithShadow__S1fL3 {
  box-shadow: 20px 28px 28px 0 rgb(0 0 0 / 45%);
}

.CarouselWithToutsMobile_slides__tqQrt {
  row-gap: var(--spacer-40);
}

.CarouselWithToutsMobile_slideItem__1ZHjR {
  grid-column: start/end;
}

.CarouselWithToutsMobile_slideDescriptionContainer__mJafv {
  margin-top: var(--spacer-24);
  grid-column: start/end;
}

/* Variables */

/* Mixins for defining chiclet positioning at each breakpoint */

/* leveraging post-css-calc https://www.npmjs.com/package/postcss-calc
to pre-calculate these values */

/* Styles */

.ChicletConstellation_chicletConstellation__Gfd48 {
  position: relative;
  z-index: var(--z-index-neg);

  --module-padding-top: 0;
}

/* used for scroll progress calculation */

.ChicletConstellation_placeholder__2WNaf {
  position: absolute;
  inset: 0 0 -20vh;
  pointer-events: none;
}

.ChicletConstellation_chiclets__q55dU {
  position: relative;
  z-index: var(--z-index-neg);
  height: 52.5rem;
  margin-right: var(--spacer-12);
  margin-bottom: var(--spacer-40);
  margin-left: var(--spacer-12);
  grid-column: wrapper-start / wrapper-end;
}

@media (min-width: 768px) {

.ChicletConstellation_chiclets__q55dU {
    height: 30.8rem
}
  }

@media (min-width: 1240px) {

.ChicletConstellation_chiclets__q55dU {
    height: 35rem;
    margin-bottom: var(--spacer-16);
    grid-column: wrapper-start / wrapper-end
}
  }

.ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa {
    --logo-translate-x: 0;
    --logo-translate-y: 0;
    --logo-parallax-distance: 10rem;
    position: absolute;
    top: 0;
    left: 0;
    opacity: calc(1 - var(--constellation-progress)*(0.9 + var(--logo-parallax-distance-multiplier)*0.1));

    --logo-parallax-distance-multiplier: 1.5;
  }

/* Positioning using values from figma converted into percentage */

.ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(1) {
    top: -8.13559%;
    left: -9.6%;
  }

.ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(2) {
    top: 5.08475%;
    left: 20%;
  }

.ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(3) {
    top: -0.33898%;
    left: 62.13333%;
  }

.ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(4) {
    top: 20.84746%;
    left: -7.73333%;
  }

.ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(5) {
    top: 29.83051%;
    left: 36.53333%;
  }

.ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(6) {
    top: 22.54237%;
    left: 70.93333%;
  }

.ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(7) {
    top: 45.59322%;
    left: -14.66667%;
  }

.ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(8) {
    top: 47.45763%;
    left: 64.53333%;
  }

.ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(9) {
    top: 68.47458%;
    left: -7.73333%;
  }

.ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(10) {
    top: 77.45763%;
    left: 49.33333%;
  }

.ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(11) {
    top: 68.47458%;
    left: 90.4%;
  }

/* hide it on mobile as per design request */

.ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(12) {
    top: -169.32203%;
    left: -266.4%;
  }

.ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(13) {
    top: 56.27119%;
    left: 23.73333%;
  }

.ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa {
    transform: translate3d(var(--logo-translate-x), calc(var(--logo-translate-y)*1px + var(--constellation-progress)*(var(--logo-parallax-distance)*var(--logo-parallax-distance-multiplier))), 0);
}

@media (min-width: 768px) {

.ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa {
      --logo-parallax-distance-multiplier: 1;
  }

      .ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(1) {
    top: -12.33766%;
    left: 10.20833%;

    --logo-parallax-distance-multiplier: 2;
  }
      .ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(2) {
    top: -5.84416%;
    left: 30.52083%;

    --logo-parallax-distance-multiplier: 2.2;
  }
      .ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(3) {
    top: 31.81818%;
    left: 40.72917%;

    --logo-parallax-distance-multiplier: 2.1;
  }
      .ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(4) {
    top: -12.33766%;
    left: 50.83333%;

    --logo-parallax-distance-multiplier: 2;
  }
      .ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(5) {
    top: -5.84416%;
    left: 71.14583%;

    --logo-parallax-distance-multiplier: 2.1;
  }
      .ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(6) {
    top: -12.33766%;
    left: 91.45833%;

    --logo-parallax-distance-multiplier: 2.2;
  }
      .ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(7) {
    top: 69.48052%;
    left: 6.66667%;

    --logo-parallax-distance-multiplier: 1.6;
  }
      .ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(8) {
    top: 36.03896%;
    left: 20.41667%;

    --logo-parallax-distance-multiplier: 1.5;
  }
      .ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(9) {
    top: 69.48052%;
    left: 50.83333%;

    --logo-parallax-distance-multiplier: 1.7;
  }
      .ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(10) {
    top: 35.71429%;
    left: 61.04167%;

    --logo-parallax-distance-multiplier: 1.5;
  }
      .ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(11) {
    top: 77.92208%;
    left: 71.25%;

    --logo-parallax-distance-multiplier: 1.4;
  }
      .ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(12) {
    top: 31.81818%;
    left: 81.35417%;

    --logo-parallax-distance-multiplier: 1.5;
  }
      .ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(13) {
    top: 77.92208%;
    left: 30.625%;

    --logo-parallax-distance-multiplier: 1.2;
  }
    }

@media (min-width: 1240px) {
      .ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(1) {
    top: -5.14286%;
    left: 8.54167%;
  }
      .ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(2) {
    top: 0.57143%;
    left: 22.5%;
  }
      .ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(3) {
    top: 20.28571%;
    left: 34.44444%;
  }
      .ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(4) {
    top: 5.14286%;
    left: 49.375%;
  }
      .ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(5) {
    top: 5.14286%;
    left: 70.90278%;
  }
      .ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(6) {
    top: 0.57143%;
    left: 85%;
  }
      .ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(7) {
    top: 35.14286%;
    left: 2.77778%;
  }
      .ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(8) {
    top: 43.71429%;
    left: 18.54167%;
  }
      .ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(9) {
    top: 48.85714%;
    left: 45.06944%;
  }
      .ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(10) {
    top: 28.28571%;
    left: 60.83333%;
  }
      .ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(11) {
    top: 71.42857%;
    left: 72.91667%;
  }
      .ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(12) {
    top: 35.14286%;
    left: 91.66667%;
  }
      .ChicletConstellation_chiclets__q55dU .ChicletConstellation_chicletItem__Nbpsa:nth-child(13) {
    top: 77.14286%;
    left: 31.11111%;
  }
    }

.ChicletConstellation_chiclets__q55dU .ChicletConstellation_contentWrapper__FDCQh {
    grid-column: start/end;
  }

.ChicletConstellation_chiclets__q55dU .ChicletConstellation_featuredLogo__jS_q4 {
    justify-content: start;
  }

@media (min-width: 768px) {

.ChicletConstellation_chiclets__q55dU .ChicletConstellation_featuredLogo__jS_q4 {
      justify-content: center
  }
    }

.ChicletItem_logo__AUGlL {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ChicletItem_icon__giBY_ {
  --graphic-scale: 6.8 / 8;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: var(--icons-border-radius);
}

@media (min-width: 1240px) {

.ChicletItem_icon__giBY_ {
    --graphic-scale: 1;
    overflow: hidden
}
  }

.WebglText_textElements__ZemSt {
  position: relative;
  grid-column: start / end;
}

.WebglText_portableText__za3Dt {
  position: relative;
  z-index: var(--z-index-1);
}

.WebglText_canvasWrapper__uXtgz {
  position: absolute;
  width: 100%;
  height: calc(100% + 200px);
  inset: -100px 0 0;
  pointer-events: none;
}

.WebglText_canvasRendered__ZWRjK span {
    /* This style is applied once our canvas is rendered, it changes the font
    color to transparent to hide the type without hiding the other UI elements
    such as the highlight color on user select. It's using !important as we
    have to be sure it overrides any other CSS rule */

    /* stylelint-disable-next-line declaration-no-important */
    color: rgb(255 255 255 / 0%) !important;
  }

.Tube_tubeContainer__Ptg18 {
  position: relative;
  z-index: var(--z-index-1);
  top: var(--tube-top-offset);
  left: calc(50% + var(--tube-left-offset, 0px));
  height: var(--tube-height);
  pointer-events: none;
  transform: translateX(-50%);

  --tube-top-offset: 0;
  --tube-height: 11rem;
}

  @media (min-width: 768px) {.Tube_tubeContainer__Ptg18 {
    --tube-height: 33rem
}
  }

  .Tube_tubeContainer__Ptg18 .Tube_asset__FVEoH {
    z-index: var(--z-index-1);
    width: auto;
    height: var(--tube-height);
  }

.Tube_assetsWrapper__7PfFw {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 1921px) {

.Tube_assetsWrapper__7PfFw {
    -webkit-mask-image: linear-gradient(to right, transparent, black 12%, black 88%, transparent);
            mask-image: linear-gradient(to right, transparent, black 12%, black 88%, transparent)
}
  }

.Tube_ballWrapper__3YB94 {
  position: absolute;
  z-index: var(--z-index-neg);
  top: 5%;
  left: 0;
  height: 71%;
  transform: translateX(-150%);
}

/* Set the origin at the center of the ball */

.Tube_ballWrapper__3YB94 .Tube_ball__GKDb3 {
    height: 100%;
  }

/* variant tube1 */

.Tube_tube1__y6Vp8 {
  --tube-height: 11rem;
}

@media (min-width: 768px) {

.Tube_tube1__y6Vp8 {
    --tube-height: 33rem
}
  }

.Tube_tube1__y6Vp8 .Tube_ballWrapper__3YB94 {
    top: 5%;
    height: 71%;
  }

.Tube_tube1__y6Vp8 .Tube_tubeSizer__Luq_4 {
    position: absolute;
    inset: 24.6% 0 0.55%;
  }

/* variant tube2 */

.Tube_tube2__7fuxz {
  --tube-height: 48.5rem;
}

.Tube_tube2__7fuxz .Tube_ballWrapper__3YB94 {
    top: 1.5%;
    height: 31%;
  }

.Tube_tube2__7fuxz .Tube_tubeSizer__Luq_4 {
    position: absolute;
    inset: 0.5% 0 66.18%;
  }

.Tube_svg__Lgu4d {
  position: absolute;
  width: auto;
  height: 100%;
}

.DistortedText_distortedText__d7ptA {
  position: relative;
}

  .DistortedText_distortedText__d7ptA .DistortedText_title2__1jqlF,
  .DistortedText_distortedText__d7ptA .DistortedText_accents__0gc_p {
    color: var(--main-font-color);
    grid-column: start / end;

    --em-color: var(--color-grey-60);
    text-align: center;
  }

  @media (min-width: 768px) {

  .DistortedText_distortedText__d7ptA .DistortedText_title2__1jqlF,
  .DistortedText_distortedText__d7ptA .DistortedText_accents__0gc_p {
      text-align: left
  }
    }

  .DistortedText_distortedText__d7ptA .DistortedText_title2__1jqlF {
    position: relative;
  }

.DistortedText_tube__KNai7 {
  position: absolute;

  --tube-top-offset: calc(100% + 10rem);
  --tube-left-offset: 17vw;
  pointer-events: none;
}

@media (min-width: 768px) {

.DistortedText_tube__KNai7 {
    --tube-left-offset: 450px;
    --tube-top-offset: 100%
}
  }

@media (min-width: 1240px) {

.DistortedText_tube__KNai7 {
    --tube-left-offset: 300px
}
  }

.DistortedText_accents__0gc_p {
  margin-top: var(--spacer-32);
  color: inherit;
}

.DistortedText_emWithDash__OmWKD {
}

@media (min-width: 768px) {

.DistortedText_openQuote__Oozn2 {
    position: absolute;
    top: 0;
    right: 100%
}
  }


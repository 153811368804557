.tubeContainer {
  position: relative;
  z-index: var(--z-index-1);
  top: var(--tube-top-offset);
  left: calc(50% + var(--tube-left-offset, 0px));
  height: var(--tube-height);
  pointer-events: none;
  transform: translateX(-50%);

  --tube-top-offset: 0;
  --tube-height: 11rem;

  @media (--md) {
    --tube-height: 33rem;
  }

  .asset {
    z-index: var(--z-index-1);
    width: auto;
    height: var(--tube-height);
  }
}

.assetsWrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @media (--xl) {
    mask-image: linear-gradient(to right, transparent, black 12%, black 88%, transparent);
  }
}

.ballWrapper {
  position: absolute;
  z-index: var(--z-index-neg);
  top: 5%;
  left: 0;
  height: 71%;
  transform: translateX(-150%);

  /* Set the origin at the center of the ball */
  .ball {
    height: 100%;
  }
}

/* variant tube1 */
.tube1 {
  --tube-height: 11rem;
  @media (--md) {
    --tube-height: 33rem;
  }

  .ballWrapper {
    top: 5%;
    height: 71%;
  }

  .tubeSizer {
    position: absolute;
    inset: 24.6% 0 0.55%;
  }
}

/* variant tube2 */
.tube2 {
  --tube-height: 48.5rem;

  .ballWrapper {
    top: 1.5%;
    height: 31%;
  }

  .tubeSizer {
    position: absolute;
    inset: 0.5% 0 66.18%;
  }
}

.svg {
  position: absolute;
  width: auto;
  height: 100%;
}

.cardGridLayout {
  composes: grid from '~g';
  margin-bottom: var(--spacer-48);
  grid-column: wrapper-start / wrapper-end;
}

.cardItems {
  composes: subgrid from '~g';
  position: relative;
  gap: var(--grid-gutter-size);

  @media (hover: hover) {
    &:has(.cardItem:hover) {
      .cardItem .cardContent {
        opacity: 0.5;
      }
    }

    .cardItem:hover {
      --border-color: var(--color-alpha-light-20);

      .cardContent {
        /* stylelint-disable-next-line declaration-no-important */
        opacity: 1 !important;
      }
    }
  }
}

.cardItem {
  border: 1px solid var(--border-color);
  grid-column: auto / span 2;
  transition: border var(--duration-500ms) var(--ease-out);

  --border-color: var(--color-alpha-light-10);

  @media (--md) {
    flex-basis: unset;
    grid-column: auto / span 4;
  }

  .cardContent {
    transition: opacity var(--duration-500ms) var(--ease-out);
  }
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  --graphic-scale: 6.8 / 8;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: var(--icons-border-radius);

  @media (--lg) {
    --graphic-scale: 1;
    overflow: hidden;
  }
}

@value bentosMultipleColumns from '~customBreakpoints';

.bentoCard {
  composes: cardHover from '~g';
  position: relative;
  display: flex;
  overflow: hidden;
  height: 100%;
  flex: 1;
  flex-direction: column;
  align-items: center;
  border-radius: var(--border-radius-10);
  background: var(--card-background);
  text-align: center;

  --lockup-justify: center;

  &::after {
    position: absolute;
    display: block;

    /* bento border is not using a color within design system */
    border: 1px solid var(--color-alpha-light-5);
    border-radius: var(--border-radius-5);
    content: '';
    inset: 0;
    pointer-events: none;
  }

  &.textPlacementTop {
    flex-direction: column;
    justify-content: flex-start;

    .mediaWrapper {
      align-items: flex-start;
    }
  }

  &.textPlacementBottom {
    flex-direction: column-reverse;

    /*  justify flex end = flex start because flex-direction is reversed */
    justify-content: flex-end;

    &.multipleRow {
      justify-content: space-between;
    }
  }

  /* fullbleed background need a specific height since the background image is positioned in absolute  */
  &.isCoverBackground {
    justify-content: flex-start;

    &.multipleRow {
      min-height: 48.3rem;
    }

    &:not(.multipleRow).bentoSizeFull {
      @media (--md) {
        min-height: 35.6rem;
      }
      @media (--lg) {
        min-height: 50rem;
      }
    }
  }
}

.content {
  z-index: var(--z-index-2);
  width: 100%;
  flex: 0 0;
  padding: 0 var(--bento-text-padding);
  margin-top: var(--spacer-40);
  margin-bottom: var(--spacer-48);

  --bento-text-padding: var(--spacer-24);

  @media (--md) {
    margin-top: var(--spacer-24);
    margin-bottom: var(--spacer-32);

    --bento-text-padding: var(--spacer-16);
  }

  @media (min-width: bentosMultipleColumns) {
    margin-top: var(--spacer-32);
    margin-bottom: var(--spacer-40);

    --bento-text-padding: var(--spacer-24);
  }

  @media (--lg) {
    margin-top: var(--spacer-40);
    margin-bottom: var(--spacer-48);
  }

  .bentoSizeOneHalf & {
    max-width: calc(40rem + var(--bento-text-padding) * 2);
  }

  .bentoSizeTwoThird &,
  .bentoSizeFull & {
    max-width: calc(42rem + var(--bento-text-padding) * 2);
  }

  .textAlignmentLeft & {
    --bento-text-padding: var(--spacer-24);
    --lockup-justify: start;

    @media (--md) {
      align-self: flex-start;

      --bento-text-padding: var(--spacer-40);
      text-align: left;
    }
  }

  .textAlignmentRight & {
    align-self: flex-end;

    --lockup-justify: end;
    --bento-text-padding: var(--spacer-24);
    text-align: right;

    @media (--md) {
      align-self: flex-start;

      --bento-text-padding: var(--spacer-40);
      text-align: left;
    }
  }
}

.mediaWrapper {
  position: relative;
  display: flex;
  flex: 0 1;
  align-items: flex-start;
  align-self: stretch;
  justify-content: center;

  &.backgroundOnly {
    align-items: flex-start;

    .backgroundWrapper .backgroundWrapper {
      height: 100%;
    }

    /* All background sizes */
    .backgroundSizeShort {
      min-height: 18.4rem;
      @media (--md) {
        min-height: 13.8rem;
      }
      @media (--lg) {
        min-height: 19.2rem;
      }
    }

    .backgroundSizeDefault {
      min-height: 21.8rem;
      @media (--md) {
        min-height: 16.1rem;
      }
      @media (--lg) {
        min-height: 22.6rem;
      }
    }

    .backgroundSizeTall {
      min-height: 26.9rem;

      @media (--md) {
        min-height: 19.6rem;
      }
      @media (--lg) {
        min-height: 27.7rem;
      }
    }

    /* 1:2 bentos background sizes */
    .bentoSizeOneHalf & {
      .backgroundSizeShort,
      .backgroundSizeDefault,
      .backgroundSizeTall {
        min-height: 21.8rem;

        @media (--md) {
          min-height: 22.7rem;
        }
        @media (--lg) {
          min-height: 30.6rem;
        }
      }
    }

    .isCoverBackground & {
      min-height: 21.8rem;

      /* Fullbleed only on medium & Large */
      @media (--md) {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;

        .backgroundWrapper {
          height: 100%;
        }
      }
    }

    .multipleRow.isCoverBackground & {
      position: absolute;
      inset: 0;
    }
  }

  &.foregroundOnly {
    &.foregroundLarge,
    &.foregroundBleed {
      justify-content: flex-start;
      @media (--lg) {
        justify-content: stretch;
      }
    }
  }

  &.backgroundAndForeground {
    .backgroundWrapper {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .backgroundMedia {
      padding-bottom: var(--spacer-54);
    }
  }
}

/* Background wrapper & media classes */
.backgroundWrapper {
  position: relative;
  z-index: var(--z-index-0);
  width: 100%;
  height: 100%;

  /* force standardized height on mobile */
  flex: 1;

  .backgroundMedia {
    position: absolute;
    top: 0;
    left: 50%;
    width: var(--bento-image-width, 100%);
    height: 100%;
    object-fit: cover;
    object-position: bottom;
    transform: translateX(-50%);
    transition: var(--card-hover-transform-default-transition);

    @media (--md) {
      object-position: center;
    }

    &.fullWidth {
      height: 100%;

      --image-aspect-ratio: unset;
    }

    &.fullWidthWithShadows {
      /* ratio for the fullWidthWithShadows, so that baked shadows go beyond the div */
      height: 155%;

      &.hotspotIsAtTop {
        object-position: top;

        @media (--md) {
          object-position: top;
        }
      }
    }
  }
}

/* Foreground */
.foregroundContainer {
  z-index: var(--z-index-1);
  width: 100%;
  max-width: 33.6rem;
  height: auto;
  border-radius: var(--border-radius-10);
  margin: var(--bento-foreground-margin);

  /* text placements related adjustment to foreground  */
  .textPlacementTop & {
    margin-top: 0;
  }

  .textPlacementBottom & {
    margin-bottom: 0;
  }

  --bento-foreground-margin: var(--spacer-32);

  @media (--md) {
    --bento-foreground-margin: var(--spacer-16);
  }

  @media (min-width: bentosMultipleColumns) {
    --bento-foreground-margin: var(--spacer-32);
  }

  @media (--lg) {
    --bento-foreground-margin: var(--spacer-40);
  }

  .foregroundIcon & {
    margin-top: var(--spacer-32);

    --bento-foreground-margin: 3.75rem;

    @media (--md) {
      margin-top: var(--spacer-16);

      --bento-foreground-margin: var(--spacer-16);
    }

    @media (min-width: bentosMultipleColumns) {
      margin-top: var(--spacer-32);

      --bento-foreground-margin: var(--spacer-32);
    }

    @media (--lg) {
      margin-top: var(--spacer-40);

      --bento-foreground-margin: 3.75rem;
    }
  }

  .withBorder {
    --border-size: 1.4rem;
  }

  .noBorder {
    --border-size: 0;
  }

  .foregroundSmall & .noBorder {
    /* small foreground that don't have border need a extra padding */
    padding: var(--spacer-6);
  }

  .foregroundMedia {
    position: relative;
    overflow: hidden;
  }

  /* Bleed only on small & meidum */
  .foregroundLarge & {
    min-width: 113%;
    max-width: auto;

    --bento-foreground-margin: var(--spacer-32);

    @media (--md) {
      max-width: 100%;

      --bento-foreground-margin: var(--spacer-48);
    }

    @media (--lg) {
      min-width: auto;

      --bento-foreground-margin: 6rem;
    }
  }
}

.bentoSizeOneHalf {
  .foregroundContainer {
    margin-top: var(--spacer-32);

    @media (--md) {
      margin-top: var(--spacer-40);
    }

    @media (--lg) {
      margin-top: 7rem;
    }
  }
}

.bentoSizeTwoThird {
  .content {
    @media (--md) {
      /* this is a fix that make sure bottom text aligns with the bento beside
      this assumes this multi-row bento is beside a bento that has a 3-line body copy.
      Which is something design will make sure with copywriter */
      min-height: 8.875rem;
    }
  }
}

/* Two third bento with bleed foreground  */
.bentoSizeTwoThird .foregroundBleed {
  .foregroundContainer {
    min-width: 122%;
    max-width: auto;
    justify-content: flex-start;

    /* hide the top border for "bleeding" effect */
    margin-top: -1rem;
    margin-left: var(--bento-foreground-margin);

    @media (--md) {
      margin-top: 0;
    }
  }
}

/* Two third bento that span over multiple rows  */
.multipleRow.bentoSizeTwoThird {
  .foregroundBleed {
    flex: 1;
    justify-content: flex-start;
    @media (--md) {
      justify-content: flex-start;
    }

    .foregroundContainer {
      margin: var(--bento-foreground-margin);
      margin-bottom: 0;
      aspect-ratio: var(--foreground-aspect-ratio, 16/9);

      --bento-foreground-margin: var(--spacer-32);

      @media (--md) {
        width: auto;
        min-width: unset;
        max-width: unset;
        height: calc(100% - var(--bento-foreground-margin) - var(--spacer-12));
        margin-right: 0;

        --bento-foreground-margin: var(--spacer-40);
      }
    }
  }
}

.bentoSizeTwoThird.bentoRowPositionEnd .foregroundBleed {
  .foregroundContainer {
    justify-content: flex-end;
  }
}

/* Full row bento that bleeds  */
.bentoSizeFull .foregroundBleed {
  .foregroundContainer {
    max-width: 100%;
    margin-top: -1rem;
    margin-right: -1rem;

    @media (--md) {
      min-width: auto;
      margin-right: var(--bento-foreground-margin);
    }

    .foregroundMedia {
      width: 100%;
      max-height: 19.4rem;
      object-fit: cover;
      object-position: bottom;

      @media (--md) {
        max-height: 24rem;
      }

      img {
        width: auto;
      }
    }
  }
}

/* Two third bentos on 1 row  */
:not(.multipleRow).bentoSizeTwoThird .foregroundBleed {
  .foregroundContainer {
    min-width: 122%;
    max-width: auto;
    justify-content: flex-start;

    /* hide the top border for "bleeding" effect */
    margin-top: -1rem;
    margin-left: var(--bento-foreground-margin);

    --bento-foreground-margin: var(--spacer-32);
    @media (--md) {
      max-width: 100%;

      --bento-foreground-margin: var(--spacer-40);
    }
    @media (--lg) {
      margin-right: var(--bento-foreground-margin);
    }

    .foregroundMedia {
      width: auto;
      height: calc(var(--foreground-media-height, 22.8rem) - var(--border-size, 0));
      object-fit: cover;
      object-position: bottom;

      @media (--md) {
        width: 100%;
        max-height: calc(var(--foreground-media-height, 23.6rem) - var(--border-size, 0));

        --foreground-media-height: 23.6rem;
      }

      img {
        width: auto;
      }
    }
  }

  &.foregroundBleedShort .foregroundContainer .foregroundMedia {
    object-fit: cover;
    object-position: bottom;

    --foreground-media-height: 19.4em;

    @media (--md) {
      --foreground-media-height: 20.2rem;
    }
  }

  &.foregroundBleedTall .foregroundContainer .foregroundMedia {
    object-fit: cover;
    object-position: bottom;

    --foreground-media-height: 27.9em;

    @media (--md) {
      --foreground-media-height: 28.7rem;
    }
  }
}

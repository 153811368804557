.cardItem {
  composes: cardHover from '~g';
  position: relative;
  text-align: left;

  --card-aspect-ratio: 1.2;
  --shadow-radius: var(--border-radius-5);

  @media (--md) {
    --card-aspect-ratio: 1.3;
  }

  @media (hover: hover) {
    &:hover {
      --item-hover-progress: 1;

      .buttonExpand {
        --button-color: var(--color-grey-00);
      }
    }
  }
}

.active {
  --card-hover-progress: 1;
  --item-hover-progress: 1;

  .textContainer {
    transition: transform var(--duration-400ms) var(--ease-basic-butter) 0.02s;
  }

  .bodyWrapper {
    opacity: 1;
    transition:
      opacity var(--duration-400ms) var(--ease-embellishment) var(--duration-60ms),
      visibility 0ms;
    visibility: visible;
  }

  .imageOverlay {
    opacity: 0;
  }

  .imageOverlayHover {
    opacity: 1;
  }
}

.innerWrapper {
  position: relative;
  z-index: var(--z-index-1);
  display: flex;
  overflow: hidden;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
  padding: var(--spacer-24);
  border-radius: var(--shadow-radius);
  aspect-ratio: var(--card-aspect-ratio, 1);

  &::after {
    position: absolute;
    border-radius: var(--shadow-radius);
    box-shadow: inset 0 0 0 0.1rem var(--color-alpha-light-5);
    content: '';
    inset: 0;
  }
}

.imageOverlay {
  transition: opacity var(--duration-400ms);
}

.imageOverlayHover {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translateY(0);
  transition: opacity var(--duration-400ms);
}

.imageContainer {
  position: absolute;
  z-index: var(--z-index-neg);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(calc(var(--card-hover-progress, 0) * var(--default-card-hover-scale-amount) + 1));
  transition: var(--card-hover-transform-default-transition);
}

.shadowContainer {
  z-index: var(--z-index-1);
}

.glow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.eyebrow,
.textContainer {
  pointer-events: none;
}

.eyebrow {
  composes: label from '~typo';
  position: absolute;
  z-index: var(--z-index-0);
  top: 0;
  left: 0;
  padding: var(--spacer-24);
  color: var(--color-accent);
  font-weight: 600;
  white-space: nowrap;
}

.textContainer {
  z-index: var(--z-index-1);
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  transform: translate3d(0, var(--translate-offset), 0);
  transition: transform var(--duration-400ms) var(--ease-embellishment) 0s;
}

.titleWrapper {
  position: relative;
}

.title {
  composes: body from '~typo';
  display: block;
  color: var(--color-grey-80);
  line-height: 1.3;
}

.bodyWrapper {
  position: absolute;
  top: 100%;
  display: flex;
  flex-flow: row wrap;
  opacity: 0;
  transition:
    opacity var(--duration-200ms) var(--ease-embellishment) 0s,
    visibility 0ms linear var(--duration-200ms);
  visibility: hidden;
}

.bodyContent {
  flex: 1 0 100%;
  margin-top: var(--spacer-12);
}

.bodies {
  composes: body from '~typo';
  margin-top: var(--spacer-8);
  color: var(--color-grey-80);

  &:first-child {
    margin-top: 0;
  }
}

.link {
  color: var(--color-grey-60);
  pointer-events: auto;
  text-decoration: underline;
}

.buttonExpand {
  position: absolute;
  z-index: var(--z-index-1);
  flex-grow: 0;
  flex-shrink: 0;
  inset: auto var(--spacer-24) var(--spacer-24) auto;
  pointer-events: none;

  --button-bg-color: var(--color-alpha-grey-80);
  --button-border-color: var(--color-alpha-light-20);
}

.buttonOverlay {
  position: absolute;
  z-index: var(--z-index-1);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
